// Angular modules
import { Component } from '@angular/core';
import { OnInit } from '@angular/core';
import { StorageKey } from '@enums/storage-key.enum';
import { StorageHelper } from '@helpers/storage.helper';
import { UserUtilsHelper } from '@security/helpers/user-utils.helper';
import { User } from '@security/models';
import { UserService } from '@security/services/user.service';
import { StoreService } from '@services/store.service';

@Component({
  selector: 'lockbin-page-layout',
  templateUrl: './page-layout.component.html',
  styleUrls: ['./page-layout.component.scss'],
})
export class PageLayoutComponent implements OnInit {
  constructor(
    private storeService: StoreService,
    private userService: UserService

  ) { }

  public ngOnInit(): void {

  }

  // -------------------------------------------------------------------------------
  // NOTE Init ---------------------------------------------------------------------
  // -------------------------------------------------------------------------------

  // -------------------------------------------------------------------------------
  // NOTE Actions ------------------------------------------------------------------
  // -------------------------------------------------------------------------------

  // -------------------------------------------------------------------------------
  // NOTE Computed props -----------------------------------------------------------
  // -------------------------------------------------------------------------------

  // -------------------------------------------------------------------------------
  // NOTE Helpers ------------------------------------------------------------------
  // -------------------------------------------------------------------------------

  // -------------------------------------------------------------------------------
  // NOTE Requests -----------------------------------------------------------------
  // -------------------------------------------------------------------------------

  // -------------------------------------------------------------------------------
  // NOTE Subscriptions ------------------------------------------------------------
  // -------------------------------------------------------------------------------
}

// Angular modules
import { Component, HostBinding } from '@angular/core';
import { Input } from '@angular/core';
import { OnInit } from '@angular/core';

@Component({
  selector: 'lockbin-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent implements OnInit {
  @Input() withLabel: boolean = false;
  @Input() isInComponentList: boolean = false;

  // Para establecer la clase CSS en el elemento Host
  @HostBinding('class') get HeadingClass() {
    return this.isInComponentList ? 'nk-progress-bar' : '';
  }

  constructor() { }

  public ngOnInit(): void {}
}

// Angular modules
import { Component, EventEmitter, Input, Output } from '@angular/core';

// External modules
import { Observable, debounceTime, distinctUntilChanged, tap, switchMap, map, catchError, of } from 'rxjs';

// Models
import { QueryParameter } from '@models/api-request.model';

@Component({
  selector: 'lockbin-custom-autocomplete',
  templateUrl: './custom-autocomplete.component.html',
  styles: [
  ]
})
export class CustomAutocompleteComponent {

  @Input() labelInputText!: string;
  @Input() placeholderText: string = 'Buscar...';
  @Input() fieldToFilter: string = 'name';
  @Input() fieldToShow: string = 'name';
  @Input() fieldValue: string = '';
  @Input() customDebounceTime: number = 100;
  @Input() searcher!: Observable<any>;
  @Input() isRequired: boolean = false;
  @Input() maxNumberOfCoincidences: number = 10;
  @Input() isReadOnly: boolean = false;
  @Input() isDisabled: boolean = false;
  @Input() isVisible: boolean = true;
  @Input() id: string = '';
  @Input() minCharsLength: number = 2;

  @Output() onSelectedItem: EventEmitter<any> = new EventEmitter();

  showErrorInvalidValue: boolean = false;

  // -------------------------------------------------------------------------------
  // #region Typeahead with add option ------------------------------------------------
  // -------------------------------------------------------------------------------
  autocompleteSearching = false;
  autocompleteSearchFailed = false;

  queryParams: QueryParameter = {};

  autocompleteSearch = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(this.customDebounceTime),
      distinctUntilChanged(),
      tap(() => {
        this.autocompleteSearching = true;
        this.showErrorInvalidValue = false;
      }),
      tap((searchedTerm: string) => {

        if(this.fieldToFilter === 'name') {
          this.queryParams.filterByParams = {
            name: searchedTerm + '*'
          }
        }
        return searchedTerm;
      }),
      switchMap((searchedTerm) =>
        this.searcher.pipe(
          tap(() => {
            this.autocompleteSearchFailed = false;
            this.showErrorInvalidValue = false;
          }),
          map((result) => {
            const filteredItems = this.fnForFilter(result.items, searchedTerm);
            this.showErrorInvalidValue = filteredItems.length === 0;
            return filteredItems;
          }),
          // tap((resp) => console.log(resp)),
          catchError(() => {
            this.autocompleteSearchFailed = true;
            this.showErrorInvalidValue = true;
            return of([]);
          })
        )
      ),

      tap(() => (this.autocompleteSearching = false))
    );

  private fnForFilter(items: any, searchedTerm: string) {

    if (searchedTerm === '*') return items.slice(0, this.maxNumberOfCoincidences);

    return searchedTerm.length < this.minCharsLength
      ? []
      : items.filter((elem: any) => elem[this.fieldToShow].toLowerCase().indexOf(searchedTerm.toLowerCase()) > -1).slice(0, this.maxNumberOfCoincidences);

  }


  autocompleteFormatter = (x: any) => {
    if (x?.name && !x?.id) {
      return x.name.slice(x.name.indexOf(':') + 1);
    }

    return x.name ?? x;
  };


  resultFormatter = (x: any) => {
    return x[this.fieldToShow];
  };


  doSelectItem(selectedItem: any) {

    this.onSelectedItem.emit(selectedItem);
  }

  doInput(selectedItem: any) {

    if (selectedItem.target.value === '')
      this.onSelectedItem.emit({});
  }
  // #endregion
  // -------------------------------------------------------------------------------


  generateUUID(): string {
    let uuid = '';
    const chars = '0123456789abcdef';

    for (let i = 0; i < 32; i++) {
      const rnd = Math.floor(Math.random() * 16);
      if (i === 8 || i === 12 || i === 16 || i === 20) {
        uuid += '-';
      }
      uuid += chars[rnd];
    }

    return uuid;
  }

}



<!-- Aquí aparecen la estructura Html que hay dentro del "modal-content" -->

<button type="button" class="btn-close close-alone" *ngIf="modalData.closable && !modalData.useHeader" aria-label="Cerrar" (click)="activeModal.dismiss()"></button>

<div class="modal-header {{ modalData.headerClasses }}" *ngIf="modalData.useHeader">
  <!-- NOTE Dynamic title -->
  <h5 class="modal-title">{{ modalData.title }}</h5>
  <button type="button" class="btn-close" *ngIf="modalData.closable" aria-label="Cerrar" (click)="activeModal.dismiss()"></button>
</div>

<div class="modal-body" [ngClass]="modalData.gapInside ? 'modal-body-' + modalData.gapInside : ''" ngbAutofocus>
  <!-- NOTE Component -->
  <ng-template modal-wrapper-host></ng-template>
</div>

<div class="modal-footer bg-light" *ngIf="modalData.footerText">
  <span class="sub-text">{{ modalData.footerText }}</span>
</div>


// Angular modules
import { Directive, ElementRef, EventEmitter, HostListener, OnInit, Output } from '@angular/core';
import { fromEvent } from 'rxjs';
import { take } from 'rxjs/operators';

@Directive({
  selector: '[lockbinClickOutside]',
})
export class ClickOutsideDirective implements OnInit {
  @Output() clickOutside = new EventEmitter();

  captured = false;

  constructor(private elRef: ElementRef) { }

  @HostListener('document:click', ['$event.target'])
  onClick(target: any) {

    if (!this.captured) {
      return;
    }

    if (!this.elRef.nativeElement.contains(target)) {
      this.clickOutside.emit(target);
    }
  }

  ngOnInit(): void {
    fromEvent(document, 'click', { capture: true })
      .pipe(take(1))
      .subscribe(() => (this.captured = true));
  }
}

// Angular modules
import { Component, Input } from '@angular/core';
import { OnInit } from '@angular/core';
import { Router } from '@angular/router';

// Internal modules
import { environment } from '@env/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'lockbin-layout-footer',
  templateUrl: './layout-footer.component.html',
  styleUrls: ['./layout-footer.component.scss']
})
export class LayoutFooterComponent implements OnInit {
  @Input() showFeder = true;
  public appName: string = environment.appName;
  public isMenuCollapsed = true;
  public year: number;
  public version = '';

  constructor
    (
      private router: Router,
      private modalService: NgbModal,
    ) {

    const date = new Date();
    this.year = date.getFullYear();
    this.version = environment.version + (!environment.production ? '.' + environment.envName : '');
  }

  public ngOnInit(): void {

  }

  openModal(event: Event, content: any) {
    event.preventDefault();
    this.modalService.open(content, { keyboard: true, size: 'lg', ariaLabelledBy: 'modal-basic-title' });
  }

  // -------------------------------------------------------------------------------
  // NOTE Init ---------------------------------------------------------------------
  // -------------------------------------------------------------------------------

  // -------------------------------------------------------------------------------
  // NOTE Actions ------------------------------------------------------------------
  // -------------------------------------------------------------------------------

  // -------------------------------------------------------------------------------
  // NOTE Computed props -----------------------------------------------------------
  // -------------------------------------------------------------------------------

  // -------------------------------------------------------------------------------
  // NOTE Helpers ------------------------------------------------------------------
  // -------------------------------------------------------------------------------

  // -------------------------------------------------------------------------------
  // NOTE Requests -----------------------------------------------------------------
  // -------------------------------------------------------------------------------

  // -------------------------------------------------------------------------------
  // NOTE Subscriptions ------------------------------------------------------------
  // -------------------------------------------------------------------------------

}


<div class="container-fluid">
  <div class="nk-content-inner">
      <div class="nk-content-body">

        <ng-content></ng-content>

      </div>
  </div>
</div>

// Enums
import { EnvName } from '@enums/environment.enum';

// External modules
import { NgxLoggerLevel } from 'ngx-logger';

// Packages
import packageInfo from '../../package.json';

const scheme = 'https://';
const host = 'api.lockbin.dev';
const port = '';
const path = '/api/backend';

const baseUrl = scheme + host + port + path;

const schemeSecurity = 'https://';
const hostSecurity = 'api.lockbin.dev';
const portSecurity = '';
const pathSecurity = '/api/auth';

const baseUrlSecurity = schemeSecurity + hostSecurity + portSecurity + pathSecurity;

export const environment = {
  production: false,
  version: packageInfo.version,
  appName: 'LockbinBackend',
  envName: EnvName.PRE,
  defaultLanguage: 'es',
  apiBaseUrl: baseUrl,
  apiBaseUrlSecurity: baseUrlSecurity,
  delayFake: 0,
  logLevel: NgxLoggerLevel.DEBUG,
  googleMapsAPIKey: 'AIzaSyAv9TjcpLL5MDb1Ha9A4z3Mr8kwV-wCDm0',
  dismissibleMiliseconds: 2000,

  persistsUserDataInLocalStorage: true


};

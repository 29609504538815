<div class="container-fluid">
    <div class="nk-header-wrap">
        <div class="nk-menu-trigger d-xl-none ms-n1">
            <a href="#" (click)="onClickActiveSidebar($event)" class="nk-nav-toggle nk-quick-nav-icon" data-target="sidebarMenu"><em class="icon ni ni-menu"></em></a>
        </div>
        <div class="nk-header-brand d-xl-none">
            <a [routerLink]="['/home']" class="logo-link">
              <img class="logo-light logo-img" src="./assets/img/logo-lockbin.svg" alt="logo">
              <img class="logo-dark logo-img" src="./assets/img/logo-lockbin.svg" alt="logo-dark">
            </a>
        </div><!-- .nk-header-brand -->


        <div class="nk-header-news d-none d-xl-block" *ngIf="false">
            <div class="nk-news-list">
                <a class="nk-news-item" href="#">
                    <div class="nk-news-icon">
                        <em class="icon ni ni-card-view"></em>
                    </div>
                    <div class="nk-news-text">
                        <p>Do you know the latest update of 2022? <span> A overview of our is now available on YouTube</span></p>
                        <em class="icon ni ni-external"></em>
                    </div>
                </a>
            </div>
        </div>

        <div class="nk-header-search ms-3 ms-xl-0" *ngIf="false">
          <em class="icon ni ni-search"></em>
          <input type="text" class="form-control border-transparent form-focus-none" placeholder="Search anything">
        </div><!-- .nk-header-news -->

        <div class="nk-header-tools" *ngIf="false">
            <ul class="nk-quick-nav">
                <li class="dropdown user-dropdown" ngbDropdown>
                    <div class="dropdown-toggle" data-bs-toggle="dropdown" ngbDropdownToggle>
                        <div class="user-toggle">
                            <div class="user-avatar sm">
                                <em class="icon ni ni-user-alt"></em>
                            </div>
                            <!-- <div class="user-info d-none d-md-block">
                                <div class="user-status text-white">Administrador</div>
                                <div class="user-name dropdown-indicator">Juan Pérez</div>
                            </div> -->
                        </div>
                    </div>
                    <div class="dropdown-menu dropdown-menu-md dropdown-menu-end dropdown-menu-s1" ngbDropdownMenu>
                        <!-- <div class="dropdown-inner user-card-wrap bg-lighter d-none d-md-block">
                            <div class="user-card">
                                <div class="user-avatar">
                                    <span>JM</span>
                                </div>
                                <div class="user-info">
                                    <span class="lead-text">Juan Pérez</span>
                                    <span class="sub-text">info@ideit.es</span>
                                </div>
                            </div>
                        </div>
                        <div class="dropdown-inner">
                            <ul class="link-list">
                                <li><a href="#"><em class="icon ni ni-setting-alt"></em><span>Configuración del perfil</span></a></li>
                            </ul>
                        </div> -->
                        <div class="dropdown-inner">
                            <ul class="link-list">
                                <li><a [routerLink]="['/autenticacion/acceso']"><em class="icon ni ni-signout"></em><span>Salir</span></a></li>
                            </ul>
                        </div>
                    </div>
                </li><!-- .dropdown -->
                <li *ngIf="false" class="dropdown notification-dropdown me-n1" ngbDropdown>
                    <div class="dropdown-toggle nk-quick-nav-icon" data-bs-toggle="dropdown" ngbDropdownToggle>
                        <div class="icon-status icon-status-info"><em class="icon ni ni-bell"></em></div>
                    </div>
                    <div class="dropdown-menu dropdown-menu-xl dropdown-menu-end dropdown-menu-s1" ngbDropdownMenu>
                        <div class="dropdown-head">
                            <span class="sub-title nk-dropdown-title">Notificaciones</span>
                            <a href="#">Marcar todas como leídas</a>
                        </div>
                        <div class="dropdown-body">
                            <div class="nk-notification">
                                <div class="nk-notification-item dropdown-inner">
                                    <div class="nk-notification-icon">
                                        <em class="icon icon-circle bg-danger-dim ni ni-curve-down-right"></em>
                                    </div>
                                    <div class="nk-notification-content">
                                        <div class="nk-notification-text">Equipo fuera de servicio</div>
                                        <div class="nk-notification-time">hace 2 horas</div>
                                    </div>
                                </div>
                                <div class="nk-notification-item dropdown-inner">
                                    <div class="nk-notification-icon">
                                        <em class="icon icon-circle bg-warning-dim ni ni-curve-down-right"></em>
                                    </div>
                                    <div class="nk-notification-content">
                                        <div class="nk-notification-text">Equipo con batería baja</div>
                                        <div class="nk-notification-time">hace 10 minutos</div>
                                    </div>
                                </div>
                            </div><!-- .nk-notification -->
                        </div><!-- .nk-dropdown-body -->
                        <div class="dropdown-foot center">
                            <a href="#">View All</a>
                        </div>
                    </div>
                </li><!-- .dropdown -->
            </ul><!-- .nk-quick-nav -->
        </div><!-- .nk-header-tools -->
    </div><!-- .nk-header-wrap -->
</div><!-- .container-fliud -->

<!-- NOTE Content -->
<ng-content></ng-content>

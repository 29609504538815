export enum CustomerType {
  EMPRESA = 0,
  AYUNTAMIENTO = 1,
  DIPUTACION = 2,
  ORGANISMO = 3
}

export function getCustomerTypes(): { value: number, name: string }[] {
  return Object.keys(CustomerType)
          .filter((key: any) => !isNaN(Number(CustomerType[key])))
                                .map((key:any) => {
                                  return { value: Number(CustomerType[key]), name: key };
  });
}


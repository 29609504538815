import { Customer } from "@customers/customers-mgmt/models";
import { QueryFilter } from "@models/api-request.model";
import { Exportable, Exporter } from "@models/index";

export class User implements Exportable {


  id:          number = 0;
  version:     number | undefined;
  login:       string | undefined;
  code:        string | undefined;
  name:        string | undefined;
  email:       string | undefined;
  newpassword: string | undefined;

  customerId:  number | undefined;

  customer:    Customer = new Customer();

  roles:     [] = [];

  constructor(){}

}

export class UserFilter implements QueryFilter {

  login:      string | undefined;

}


export class UserExporter implements Exporter  {

  login;
  name;
  email;
  customer;



  constructor() {

    this.login= {
      nameToShow: 'Login'
    };
    this.name= {
      nameToShow: 'Nombre'
    };
    this.email= {
      nameToShow: 'Email'
    };
    this.customer= {
      name: {
        nameToShow: 'Cliente'
      }
    };
  }
}





// Angular modules
import { Injectable } from '@angular/core';

// External modules
import saveAs from 'file-saver';
import * as ExcelJS from 'exceljs';

@Injectable()
export class ExcelExporterHelper {


  /**
   * Saves data into an Excel File
   * @param data Entity array with data and header to be saved, i. e.: [ { "id": 1, "name": "ESPAÑA", "code": "es-ES" }, { "id": 2, "name": "PORTUGAL", "code": "pt-PT" } ],
   * @param filename Name of the file to be saved
   * @param worksheetName
   */
  public static exportToExcel<T>(data: any[], filename: string = 'ExportedData', worksheetName:string = 'Hoja 1') {

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(worksheetName);

    // header has to be passed as an array
    worksheet.addRow(Object.keys(data[0]) );

    // row has to be passed as arrays
    data.forEach( jsonSingleObject => {
      worksheet.addRow(Object.values(jsonSingleObject));
    });

    workbook.xlsx.writeBuffer().then((buffer: any) => {
      const blob = new Blob([buffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      saveAs(blob, `${filename}.xlsx`);
    });
  }
}

// Angular Modules
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

// External Modules
import { forkJoin, map, Observable, of, switchMap } from 'rxjs';

// Models
import { QueryParameters, QueryResults } from '@models/index';
import { City } from '@configuration/cities/models/index';

// Services
import { HttpQueryUtils } from '@services/http-query-utils.service';
import { Region } from '@configuration/regions/models';
import { RegionService } from '@configuration/regions/services/region.service';

const BACKEND_API = `${environment.apiBaseUrl}`;
const ENTITY = 'city'


@Injectable({
  providedIn: 'root'
})
export class CityService {

  constructor(
    private http: HttpClient,
    public httpQueryUtils: HttpQueryUtils,
    private regionService: RegionService) { }

  // get httpParams() {
  //   return new HttpParams()//.set('fields', 'name,capital,alpha2Code,flags.png,population');
  // }


  find(queryParameters: QueryParameters): Observable<QueryResults<City>> {

    const url = `${BACKEND_API}/${ENTITY}/`;

    let queryResults$ = this.httpQueryUtils.executeQuery<City>(url, queryParameters)
      .pipe(
        switchMap((queryResults: QueryResults<City>) => {

          // return empty Observable in case of no items
          if (queryResults.items.length === 0) return of(queryResults);

          // gets an array of Observables foreach element in "queryResults.items"
          const requestDetail_1: Observable<Region>[] = this.getDetailObservablesRegion(queryResults.items);

          return forkJoin(requestDetail_1)
            .pipe(
              map(arrayWithDetailData => {

                const resultDetail_1 = arrayWithDetailData;

                queryResults.items
                  .map((item, i) => {
                    item.region = resultDetail_1[i];
                    return item;
                  });

                return queryResults;
              })
            );
        })
      )

    return queryResults$;
  }

  /**
   *
   * @param items array of items
   * @returns Array of Observables for get detailed data (findById)
  */
  private getDetailObservablesRegion(items: City[]): Observable<Region>[] {

    return items.map((item: City) => {

      if (item.regionId) {
        return this.regionService.findById(item.regionId)
      } else {
        return of(new Region());
      }

    });
  }


  delete(id: number): Observable<any> {

    const url = `${BACKEND_API}/${ENTITY}/${id}/`;

    return this.http.delete(url);
  }


  deleteBulk(ids: Array<number>): Observable<any> {

    const colObservablesToDelete= [];

    for (const id of ids) {
      colObservablesToDelete.push(this.delete(id));
    }

    return forkJoin( colObservablesToDelete );
  }


  add(entity: City): Observable<City> {

    const url = `${BACKEND_API}/${ENTITY}/`;

    return this.http.post<City>(url, entity);
  }


  update(entity: City): Observable<City> {

    const url = `${BACKEND_API}/${ENTITY}/${entity.id}/`;

    return this.http.put<City>(url, entity);
  }


  findById(id: number): Observable<City> {

    const url = `${BACKEND_API}/${ENTITY}/${id}/`;

    return this.httpQueryUtils.executeQuery<City>(url, {})
      .pipe(
        map((queryResults: QueryResults<City>) => queryResults.items.slice(0, 1)),
        switchMap((items: City[]) => {

          // gets arrays of Observables foreach element in "queryResults.items"
          const requestDetail_1: Observable<Region>[] = this.getDetailObservablesRegion(items);


          // la caña de España!
          return forkJoin(requestDetail_1)
            .pipe(
              map((arrayWithDetailData: any) => { // each item array is detail data from one Observable.

                const resultDetail_1 = arrayWithDetailData;

                items
                  .map((item: City, i) => {

                    item.region = resultDetail_1[i];
                    return item;
                  });

                return items[0];
              })
            );


        })
      )
  }


}

import { Pipe, PipeTransform } from '@angular/core';
import { Customer } from '@customers/customers-mgmt/models';

@Pipe({
  name: 'customerPipe'
})
export class CustomerPipe implements PipeTransform {



  transform(entity: Customer, ...args: unknown[]): string {

    if (entity && args && args.length > 0) {

      switch (args[0]) {
        case 'short':
          return entity.code || '';
        case 'medium':
          return entity.name || '';
        case 'long':
          return (entity.code + ' - ' + entity.name) || '';

        default:
          return entity?.name || '-';
      }
    }

    return entity?.name || '-';
  }

}

<!-- <p>usePagination: {{ usePagination }} - totalItems: {{ totalItems }} - itemsPerPage: {{ itemsPerPage }} - currentPage: {{ currentPage }} - numOfPages: {{ numOfPages }}</p> -->
<div class="g" *ngIf="usePagination && mode === 'buttons'" aria-label="Paginación">
  <ul class="pagination justify-content-center justify-content-md-start">
      <li class="page-item" [class.disabled]="!showPrevious()">
        <button class="page-link" (click)="goToPreviousPage()" [attr.tabindex]="!showPrevious() ? '-1' : null" [attr.aria-disabled]="!showPrevious() ? 'true' : null">Anterior</button>
      </li>
      <li class="page-item" *ngFor="let page of paginationButtons;" [class.active]="page === currentPage" [attr.aria-current]="page === currentPage ? 'page' : null">
        <button class="page-link" (click)="goToPage(page)" *ngIf="page > 0">{{ page }}</button>
        <span class="page-link page-more" *ngIf="page === 0"><em class="icon ni ni-more-h"></em></span>
      </li>
      <li class="page-item" [class.disabled]="!showNext()">
        <button class="page-link" (click)="goToNextPage()" [attr.tabindex]="!showNext() ? '-1' : null" [attr.aria-disabled]="!showNext() ? 'true' : null">Siguiente</button>
      </li>
  </ul>
</div>
<div class="g" *ngIf="usePagination && mode === 'selector'" aria-label="Paginación">
  <div class="pagination-goto d-flex justify-content-center justify-content-md-start gx-3">
      <div>Página</div>
      <div>
          <select #paginationSelect class="form-select js-select2" data-search="on" data-dropdown="xs center" (change)="goToPage(convertToNumber(paginationSelect.value))">
            <option *ngFor="let page of paginationItems;" [value]="page" [selected]="page === currentPage">{{ page }}</option>
          </select>
      </div>
      <div>de {{ numOfPages }}</div>
  </div>
</div>

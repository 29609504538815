import { Injectable } from "@angular/core";
import { UserAuthApiDto, UserViewDto } from "@security/models";
import { StoreService } from "@services/store.service";

@Injectable()
export class UserUtilsHelper {

  constructor(
    private storeService: StoreService
  ) {

  }


  public static transformUserApiDtoToUserViewDto(originalUser: UserAuthApiDto): UserViewDto {

    const toret: UserViewDto = new UserViewDto();

    toret.id = originalUser.id;
    toret.idCustomer = originalUser.idCustomer;
    toret.username = originalUser.username;
    toret.roles = originalUser.roles;

    toret.customer = originalUser.customer;

    return toret;

  }

  // public static getUserFormattedDataFromUserViewDto(userViewDto: UserViewDto) {

  //   if (!userViewDto) return '';

  //   const toret = userViewDto.login + '(' + userViewDto.name + ')';

  //   return toret;

  // }

  // public static getUserViewDtoFromMemorySubject(userSerialized: string): UserViewDto | undefined {

  //   if (userSerialized) {
  //     return JSON.parse(userSerialized);
  //   }

  //   return undefined;

  // }
}

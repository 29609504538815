<div class="simplebar-wrapper">
  <div class="simplebar-height-auto-observer-wrapper">
    <div class="simplebar-height-auto-observer"></div>
  </div>
  <div class="simplebar-mask">
    <div class="simplebar-offset">
      <div class="simplebar-content-wrapper" tabIndex="0" role="region" [ariaLabel]="ariaLabel">
        <div class="simplebar-content">
          <ng-content></ng-content>
        </div>
      </div>
    </div>
  </div>
  <div class="simplebar-placeholder"></div>
</div>
<div class="simplebar-track simplebar-horizontal">
  <div class="simplebar-scrollbar"></div>
</div>
<div class="simplebar-track simplebar-vertical">
  <div class="simplebar-scrollbar"></div>
</div>

import { Customer } from "@customers/customers-mgmt/models";

export class UserViewDto {

  id:          number = 0;
  username:    string | undefined;
  login:       string | undefined;
  code:        string | undefined;
  name:        string | undefined;
  email:       string | undefined;
  idCustomer:  number | undefined;
  roles:       string[] = [];


  customer:    Customer = new Customer();

  lastAccess:  Date = new Date();

  constructor(){}

}

// Angular modules
import { Injectable } from '@angular/core';

@Injectable()
export class ExporterHelper {


  public static getCollectionToExport(col: any[], itemExporter: any): object[] {
    return col.map((item) => this.getExportableItem(item, itemExporter));
  }

  /**
   *
   * @param itemToExport: Entity (normal object) with data
   * @param exporter: Object with an estructure like this:
   *        {
   *           code: {
   *             nameToShow: 'Código'
   *           },
   *           i18n: {
   *             nameToShow: 'Idioma'
   *           },
   *           country: {
   *             name: {
   *               nameToShow: 'País'
   *             }
   *           }
   *        }
   * @returns a plain object { property: value, ... }
   */
  public static getExportableItem(itemToExport: any, exporter: any): object {
    let toret: any = {};

    for (let property in exporter) {

      // exists itemToExport[property].nameToShow => is a single property
      // like "code: { nameToShow: 'Código' }"
      if (exporter[property].nameToShow) {
        if (itemToExport.hasOwnProperty(property)) {
          // the entity also has that property
          const propertyNameToExport = exporter[property]['nameToShow'];
          const propertyValueToExport = itemToExport[property];

          toret[propertyNameToExport] = propertyValueToExport;
        }
      } else {
        // is an object property
        // like "country { name: { nameToShow: 'País' } }"

        const propertyThatIsAnObject = exporter[property];


        for (const p2 in propertyThatIsAnObject) {

          if (propertyThatIsAnObject[p2].nameToShow) {
            {
              const propertyNameToExport = propertyThatIsAnObject[p2]['nameToShow'];
              const propertyValueToExport = itemToExport[property] ? itemToExport[property][p2]: '';

              toret[propertyNameToExport] = propertyValueToExport;
            }
          }

        }

      }
    }

    return toret;
  }
}

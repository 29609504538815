// Angular modules
import { Directive, ElementRef, EventEmitter, HostBinding, HostListener, Input, OnChanges, OnInit, Output, Renderer2, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[lockbinButtonLoading]',
})
export class ButtonLoadingDirective implements OnInit, OnChanges {
  @Input() lockbinButtonLoading: boolean = false;

  private cssClassLoading = 'is-loading';

  constructor(
    private elRef: ElementRef,
    private renderer: Renderer2) {
  }

  ngOnInit(): void {
    this.elRef.nativeElement.classList.add('btn-spinner-loading');

    const loadingText = this.renderer.createText('Cargando...');

    const content = this.renderer.createElement('div');
    this.renderer.addClass(content, 'visually-hidden');
    this.renderer.appendChild(content, loadingText);

    const spinner = this.renderer.createElement('div');
    this.renderer.addClass(spinner, 'spinner-loading');
    this.renderer.addClass(spinner, 'spinner-overhead');
    this.renderer.addClass(spinner, 'spinner-grow');
    this.renderer.addClass(spinner, 'text-primary');
    this.renderer.addClass(spinner, 'spinner-grow-sm');

    this.renderer.appendChild(spinner, content);

    this.renderer.appendChild(this.elRef.nativeElement, spinner);

    this.toggleLoadingVisibility();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.toggleLoadingVisibility();
  }

  toggleLoadingVisibility() {
    const wrapper = this.elRef.nativeElement.querySelector('.spinner-loading');
    if (wrapper) {
      this.lockbinButtonLoading ? this.renderer.removeStyle(wrapper, 'display') : this.renderer.setStyle(wrapper, 'display', 'none');
    }
    this.lockbinButtonLoading ? this.elRef.nativeElement.classList.add(this.cssClassLoading) : this.elRef.nativeElement.classList.remove(this.cssClassLoading);
  }

}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'BooleanPipe'
})
export class BooleanPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    return value ? "{{ 'YES' | translate }}" : "{{ 'NO' | translate }}";
  }

}

// Angular modules
import { Component, ViewChild } from '@angular/core';
import { Input } from '@angular/core';
import { OnInit } from '@angular/core';
import { environment } from '@env/environment';
import { NgbAlert } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'lockbin-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent {

  @ViewChild(NgbAlert) alertInternalComponent!: NgbAlert;

  @Input() type: string = 'info'; // primary, secondary, success, info, warning, danger, gray, light
  @Input() icon: string = '';
  @Input() dismissible: boolean = false;
  @Input() isFilled: boolean = false;
  @Input() dismissibleMiliseconds!: number;
  @Input() content: string = '';

  constructor() {
  }


  close() {
    this.alertInternalComponent.close();
  }

  dismissAlert() {
    if (this.dismissibleMiliseconds && this.content) {
      setTimeout(() => this.alertInternalComponent?.close(), this.dismissibleMiliseconds);
    }
  }

  hideAlert() {
    this.content = '';
  }
}

import { City } from "@configuration/cities/models";
import { QueryFilter } from "@models/api-request.model";
import { Exportable, Exporter } from "@models/index";
import { CustomerType } from '../../enums/customer.enum';

export class Customer implements Exportable {


  id:        number = 0;
  version:   number | undefined;
  name:      string | undefined;
  code:      string | undefined;
  fiscalId:   string | undefined;
  address:   string | undefined;
  postalCode:string | undefined;
  phone1:    string | undefined;
  phone2:    string | undefined;
  email:     string | undefined;
  web:       string | undefined;
  latitude:  number | undefined;
  longitude: number | undefined;
  customerType: CustomerType = CustomerType.ORGANISMO;
  cityId:    number =0;
  city:      City = new City();

  constructor(){}

}

export class CustomerFilter implements QueryFilter {

  name:      string | undefined;
  code?:     string | undefined;

}


export class CustomerExporter implements Exporter  {

  name;
  code;
  fiscalId;
  address;
  postalCode;
  phone1;
  phone2;
  email;
  web;
  latitude;
  longitude;
  city;



  constructor() {

    this.name= {
      nameToShow: 'Nombre'
    };
    this.code = {
      nameToShow: 'Código'
    };
    this.fiscalId = {
      nameToShow: 'Id. Fiscal'
    };
    this.address = {
      nameToShow: 'Dirección'
    };
    this.postalCode = {
      nameToShow: 'CP'
    };
    this.phone1 = {
      nameToShow: 'Tel. 1'
    };
    this.phone2 = {
      nameToShow: 'Tel. 2'
    };
    this.email = {
      nameToShow: 'Correo-e'
    };
    this.web = {
      nameToShow: 'Web'
    };
    this.latitude = {
      nameToShow: 'Latitud'
    };
    this.longitude = {
      nameToShow: 'Longitud'
    };
    this.city = {
      name: {
        nameToShow: 'Ciudad'
      }
    };
  }
}





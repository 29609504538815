// Angular modules
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BUS_EVENT_TYPE } from '@enums/bus-event-type.enum';
import { IHeaderData } from '@models/header-data.interface';
import { IMenuItem } from '@security/models/menu-item.interface';
import { MenuService } from '@security/services/menu.service';
import { EventBusService } from '@services/event-bus.service';

@Component({
  selector: 'lockbin-layout-header-background',
  templateUrl: './layout-header-background.component.html',
  styleUrls: ['./layout-header-background.component.scss']
})
export class LayoutHeaderBackgroundComponent {

  private defaultBackgroundPath = 'assets/img/backgrounds/';
  private defaultBackground = 'header-background.jpg';

  title = 'Lock.Bin';
  backgroundSrc = 'header-background.jpg';

  subMenuItems!: IMenuItem[];

  constructor(private eventBusService: EventBusService, private router: Router, private menuService: MenuService, protected activatedRoute: ActivatedRoute,) {

    this.backgroundSrc = this.defaultBackgroundPath + this.defaultBackground;

    // Obtenemos el evento de cambio de información de la cabecera
    this.eventBusService.on(BUS_EVENT_TYPE.LOAD_HEADER_DATA, (headerData: IHeaderData) => this.setHeaderData(headerData));

    this.eventBusService.on(BUS_EVENT_TYPE.LOAD_HEADER_SUBMENU, (idParent: string) => this.setSubmenu(idParent));

  }

  private setHeaderData(headerData: IHeaderData) {

    if (headerData.title) {
      this.title = headerData.title;
    }

    if (headerData.background) {
      this.backgroundSrc = this.defaultBackgroundPath + headerData.background;
    }

  }

  private setSubmenu(idParent: string) {

    console.log(this.menuService.actualItems);

    const menuItem = this.menuService.actualItems.find((menuItem) => menuItem.id === idParent);

    console.log(menuItem);

    if (menuItem) {
      this.subMenuItems = menuItem.childs;
    }

  }
}

// Angular modules
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { OnInit } from '@angular/core';

@Component({
  selector: 'lockbin-cell-currency',
  templateUrl: './cell-currency.component.html',
  styleUrls: ['./cell-currency.component.scss'],
})
export class CellCurrencyComponent implements OnInit {
  @Input() amount = 0.0;
  @Input() currency = 'EUR';
  @Output() doClick: EventEmitter<boolean> = new EventEmitter();

  constructor() {
  }

  public ngOnInit(): void {

  }

  public onClick(): void {
    this.doClick.emit();
  }

}

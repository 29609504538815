import { QueryFilter } from "@models/api-request.model";
import { Exportable, Exporter } from "@models/index";

export class Country implements Exportable {


  id:        number = 0;
  version:   number | undefined;
  name:      string | undefined;
  code:      string | undefined;
  cee:       boolean =true;
  euro:      boolean = true;
  sepa:      boolean = true;
  i18n:      string = 'es-ES';

  constructor(){}

}

export class CountryFilter implements QueryFilter {

  name:      string | undefined;
  code?:     string | undefined;

}


export class CountryExporter implements Exporter  {

  name;
  code;


  constructor() {

    this.name= {
      nameToShow: 'Nombre'
    };
    this.code= {
      nameToShow: 'Codigo'
    };
  }
}






export enum DEVICE_STATUS_TYPE {

  ADDED = 0,
  REGISTERED = 1,
  VALIDATED = 2,
  ERROR = 3,
  REQUIRE_REVISION = 4,
  DISCONTINUED = 5

}


export function getDeviceStatusTypes(): { value: number, name: string }[] {

  return Object.keys(DEVICE_STATUS_TYPE)
  .filter((key: any) => !isNaN(Number(DEVICE_STATUS_TYPE[key])))
  .map((key: any) => {
    return { value: Number(DEVICE_STATUS_TYPE[key]), name: key };
  });
}

import { Pipe, PipeTransform } from '@angular/core';
import { getDeviceModeTypes } from '@enums/device-mode-type.enum';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'DeviceModeTypePipe'
})
export class DeviceModeTypePipe implements PipeTransform {

  deviceModeTypes: any[] = [];

  constructor(private translateService: TranslateService) {}

  transform(value: unknown, arg1?: any[]): unknown {

    const idToBeSearched = value;

    if (arg1 && arg1?.length > 0)
      this.deviceModeTypes= arg1;
    else
      this.deviceModeTypes = getDeviceModeTypes();


    if (this.deviceModeTypes?.length>0) {

      const itemFounded = this.deviceModeTypes.find( item => item.value === idToBeSearched);
      if (itemFounded) return this.translateService.instant(itemFounded.name);
    }

    return this.translateService.instant('DEVICE_MODE_TYPE_ID_NOT_FOUND');
  }

}

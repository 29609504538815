import { Pipe, PipeTransform } from '@angular/core';
import { Equipment } from '@equipments/equipments-mgmt/models';

@Pipe({
  name: 'equipmentPipe'
})
export class EquipmentPipe implements PipeTransform {



  transform(entity: Equipment | undefined, ...args: unknown[]): string {

    if (entity && args && args.length > 0) {

      switch (args[0]) {
        case 'short':
          return entity.serial || '';
        case 'medium':
          return entity.serial || '';
        case 'long':
          return entity.serial || '';
        default:
          return entity.serial || '-';
      }
    }

    return entity?.serial || '-';
  }

}

// Angular Modules
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

// External Modules
import { forkJoin, map, Observable, of, switchMap } from 'rxjs';

// Models
import { QueryParameters, QueryResults } from '@models/index';
import { Region } from '@configuration/regions/models/index';

// Services
import { HttpQueryUtils } from '@services/http-query-utils.service';
import { CountryService } from '@configuration/countries/services/country.service';
import { Country } from '@configuration/countries/models';

const BACKEND_API = `${environment.apiBaseUrl}`;
const ENTITY = 'region'


@Injectable({
  providedIn: 'root'
})
export class RegionService {

  constructor(
    private http: HttpClient,
    public httpQueryUtils: HttpQueryUtils,
    private countryService: CountryService) { }


  find(queryParameters: QueryParameters): Observable<QueryResults<Region>> {

    const url = `${BACKEND_API}/${ENTITY}/`;

    let queryResults$ =  this.httpQueryUtils.executeQuery<Region>( url, queryParameters)
      .pipe(
        switchMap((queryResults: QueryResults<Region>) => {

          // return empty Observable in case of no items
          if (queryResults.items.length === 0) return of(queryResults);

          // gets an array of Observables foreach element in "queryResults.items"
          const requestDetail: Observable<Country>[] = this.getDetailObservablesCountry(queryResults.items);

          return forkJoin(requestDetail)
            .pipe(
              map(arrayWithDetailData => {
                for (let i = 0; i < queryResults.items.length; i++) {
                  queryResults.items[i].country = arrayWithDetailData[i];
                }
                return queryResults;
              })
            );
        })
      )

    return  queryResults$;
  }


  private getDetailObservablesCountry(items: Region[]): Observable<Country>[] {

    return items.map((region: Region) => {

      if(region.countryId) {
        return this.countryService.findById(region.countryId)
      } else {
        return of(new Country());
      }

    });

  }

  delete(id: number): Observable<any> {

    const url = `${BACKEND_API}/${ENTITY}/${id}/`;

    return this.http.delete(url);
  }


  deleteBulk(ids: Array<number>): Observable<any> {

    const colObservablesToDelete= [];

    for (const id of ids) {
      colObservablesToDelete.push(this.delete(id));
    }

    return forkJoin( colObservablesToDelete );
  }


  add(entity: Region): Observable<Region> {

    const url = `${BACKEND_API}/${ENTITY}/`;

    return this.http.post<Region>(url, entity);
  }


  update(entity: Region): Observable<Region> {

    const url = `${BACKEND_API}/${ENTITY}/${entity.id}/`;

    return this.http.put<Region>(url, entity);
  }


  findById(id: number): Observable<Region> {

    const url = `${BACKEND_API}/${ENTITY}/${id}/`;

    return this.httpQueryUtils.executeQuery<Region>(url, {})
      .pipe(
        map((queryResults: QueryResults<Region>) => queryResults.items.slice(0, 1)),
        switchMap((items: Region[]) => {

          // gets arrays of Observables foreach element in "queryResults.items"
          const requestDetail_1: Observable<Country>[] = this.getDetailObservablesCountry(items);


          // la caña de España!
          return forkJoin(requestDetail_1)
            .pipe(
              map((arrayWithDetailData: any) => { // each item array is detail data from one Observable.

                const resultDetail_1 = arrayWithDetailData;

                items
                  .map((item: Region, i) => {

                    item.country = resultDetail_1[i];
                    return item;
                  });

                return items[0];
              })
            );


        })
      )
  }

}

// Angular modules
import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { OnInit } from '@angular/core';

@Component({
  selector: 'lockbin-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnInit {
  @Input() mode = 'buttons'; // buttons | selector
  @Input() itemsPerPage = 10;
  @Input() totalItems = 0;
  @Input() currentPage = 1;
  @Input() maxPaginationButtons = 6;
  @Output() paginationChange: EventEmitter<number> = new EventEmitter();

  public usePagination = false;
  public numOfPages = 0;
  public paginationItems: Array<number> = [];
  public paginationButtons: Array<number> = [];

  constructor() {}

  public ngOnInit(): void {
    this.createPagination();
  }

  ngOnChanges() {
    console.log('Se ha cambiado la paginación a totalItems: ', this.totalItems, ' currentPage: ', this.currentPage);
    this.createPagination();
  }

  public createPagination() {
    console.log('USE PAGINATION 1: ', this.totalItems > this.itemsPerPage);
    this.usePagination = this.totalItems > 0 && this.totalItems > this.itemsPerPage;

    if (this.usePagination) {
      this.paginationItems = [];
      this.paginationButtons = [];
      this.numOfPages = Math.ceil(this.totalItems / this.itemsPerPage);

      // Creamos los botones para los botones de paginación
      this.buildButtons();

      // Creamos el listado de páginas para el selector desplegable
      for (let index = 1; index <= this.numOfPages; index++) {
        this.paginationItems.push(index);
      }
    }
  }

  public convertToNumber(string: string): number {
    return Number(string);
  }

  public showPrevious() {
    return this.currentPage > 1;
  }

  public showNext() {
    return this.currentPage < this.numOfPages;
  }

  public goToPage(pageNum: number) {
    if (pageNum != this.currentPage) {
      this.paginationChange.emit(pageNum);
    }
  }

  public goToPreviousPage() {
    this.goToPage(this.currentPage - 1);
  }

  public goToNextPage() {
    this.goToPage(this.currentPage + 1);
  }

  private buildButtons() {
    if (this.numOfPages > this.maxPaginationButtons) {
      let left = this.currentPage - 2;
      let right = this.currentPage + 2 + 1;
      const pagesRange = [];

      for (let index = 1; index <= this.numOfPages; index++) {
        if (index === 1 || index === this.numOfPages || (index >= left && index < right)) {
          pagesRange.push(index);
        }
      }

      let l;
      for (let index of pagesRange) {
        if (l) {
          if (index - l === 2) {
            this.paginationButtons.push(l + 1);
          } else if (index - l !== 1) {
            this.paginationButtons.push(0);
          }
        }
        this.paginationButtons.push(index);
        l = index;
      }
    } else {
      for (let index = 1; index <= this.numOfPages; index++) {
        this.paginationButtons.push(index);
      }
    }
  }
}

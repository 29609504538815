<div class="form-group form-autocomplete"
  [class.autocomplete-is-disabled]="isDisabled"
  [ngClass]="{ 'is-invalid' : this.showErrorInvalidValue }"
  *ngIf="isVisible">
  <label *ngIf="labelInputText" class="form-label">{{ labelInputText }} <span *ngIf="isRequired" class="form-required">*</span></label>
  <div class="form-control-wrap">
    <div class="form-icon form-icon-right">
      <em class="icon ni ni-search"></em>
    </div>
    <input [id]="id" type="text" class="form-control"
      [placeholder]="placeholderText"
      [ngbTypeahead]="autocompleteSearch"
      [inputFormatter]="autocompleteFormatter"
      [resultFormatter]="resultFormatter"
      [readOnly]="isReadOnly"
      [disabled]="isDisabled"
      [value]="fieldValue"
      (selectItem)="doSelectItem($event)"
      (input)="doInput($event)"
      [ngClass]="{ 'is-invalid' : this.showErrorInvalidValue }"
    />
    <div class="invalid-feedback" *ngIf="this.showErrorInvalidValue">
      {{ 'NOT_VALID_VALUE' | translate }}
    </div>
  </div>
</div>

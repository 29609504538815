// Angular modules
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { OnInit } from '@angular/core';
import { DataListEventRowOperation, DataListOperation } from '@models/index';

@Component({
  selector: 'lockbin-row-operations',
  templateUrl: './row-operations.component.html',
  styleUrls: ['./row-operations.component.scss'],
})
export class RowOperationsComponent implements OnInit {

  @Input() rowOperations?: Array<any>;
    //TODO: Esto hay que cambiarlo por lo de abajo. Se hace así por compatibilidad con el listado de usuarios
  // @Input() rowOperations?: Array<DataListOperation>;

  @Input() item?: any;
  @Output() doClick: EventEmitter<DataListEventRowOperation> = new EventEmitter<DataListEventRowOperation>();

  constructor() {}

  public ngOnInit(): void {}

  public onClick(operation: string): void {
    this.doClick.emit({ operation: operation, item: this.item });
  }

}

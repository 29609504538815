import { Pipe, PipeTransform } from '@angular/core';
import { getDeviceStatusTypes } from '@enums/device-status-type.enum';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'DeviceStatusTypePipe'
})
export class DeviceStatusTypePipe implements PipeTransform {

  deviceStatusTypes: any[] = [];

  constructor(private translateService: TranslateService) {}

  transform(value: unknown, arg1?: any[]): unknown {

    const idToBeSearched = value;

    if (arg1 && arg1?.length > 0)
      this.deviceStatusTypes= arg1;
    else
      this.deviceStatusTypes = getDeviceStatusTypes();


    if (this.deviceStatusTypes?.length>0) {

      const itemFounded = this.deviceStatusTypes.find( item => item.value === idToBeSearched);
      if (itemFounded) return this.translateService.instant(itemFounded.name);
    }

    return this.translateService.instant('DEVICE_STATUS_TYPE_ID_NOT_FOUND');
  }

}

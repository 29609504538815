// Angular Modules
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

// External Modules
import { forkJoin, map, Observable, of, switchMap } from 'rxjs';

// Enums
import { SearchStrategy } from '@enums/search-strategy.enum';

// Models
import { QueryParameters, QueryResults } from '@models/index';
import { User } from '@security/models/index';
import { Customer } from '@customers/customers-mgmt/models';

// Services
import { HttpQueryUtils } from '@services/http-query-utils.service';
import { CustomerService } from '@customers/customers-mgmt/services/customer.service';

const BACKEND_API = `${environment.apiBaseUrl}`;
const ENTITY = 'user'


@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private http: HttpClient,
    public httpQueryUtils: HttpQueryUtils,
    private customerService: CustomerService) { }

  // get httpParams() {
  //   return new HttpParams()//.set('fields', 'name,capital,alpha2Code,flags.png,population');
  // }

  find(queryParameters: QueryParameters, searchStrategy?: SearchStrategy): Observable<QueryResults<User>> {

    const url = `${BACKEND_API}/${ENTITY}/`;

    let queryResults$ = this.httpQueryUtils.executeQuery<User>(url, queryParameters);

    if (searchStrategy === SearchStrategy.EAGER) {
      queryResults$ = queryResults$.pipe(
        switchMap((queryResults: QueryResults<User>) => {

          // return empty Observable in case of no items
          if (queryResults.items.length === 0) return of(queryResults);

          return this.fillRelatedAttributes(queryResults.items)
            .pipe(
              map(() => queryResults))
        })
      )

    }
    return queryResults$;
  }


  findById(id: number, strategy?: SearchStrategy): Observable<User> {

    const url = `${BACKEND_API}/${ENTITY}/${id}/`;

    let queryResults$ = this.httpQueryUtils.executeQuery<User>(url, {})
      .pipe(
        map(result => result.items[0])
      );


    if (strategy === SearchStrategy.EAGER) {

      queryResults$ = queryResults$
        .pipe(
          switchMap((itemParent: User) => {

            return this.fillRelatedAttributes([itemParent])
              .pipe(
                map((items) => items[0]))
          })
        )
    }
    return queryResults$;
  }


  private fillRelatedAttributes(items: User[]) {

    const requestDetail_1: Observable<Customer>[] = this.getDetailObservablesCustomer(items);

    return forkJoin([
      ...requestDetail_1
    ])
      .pipe(
        map((arrayWithDetailData: any) => {

          const arrLength = items.length;

          const resultDetail_1 = arrayWithDetailData.slice(0, arrLength);

          items
            .map((item, i) => {

              item.customer = resultDetail_1[i];

              return item;
            });

          return items;
        })
      );
  }

  /**
   *
   * @param items array of items
   * @returns Array of Observables for get detailed data (findById)
   */
  private getDetailObservablesCustomer(items: User[]): Observable<Customer>[] {

    return items.map((item: User) => {

      if (item.customerId && item.customerId>0) {
        return this.customerService.findById(item.customerId)
      } else {
        return of(new Customer());
      }

    });

  }

  delete(id: number): Observable<any> {

    const url = `${BACKEND_API}/${ENTITY}/${id}/`;

    return this.http.delete(url);
  }


  deleteBulk(ids: Array<number>): Observable<any> {

    const colObservablesToDelete= [];

    for (const id of ids) {
      colObservablesToDelete.push(this.delete(id));
    }

    return forkJoin( colObservablesToDelete );
  }


  add(entity: User): Observable<User> {

    const url = `${BACKEND_API}/${ENTITY}/`;

    return this.http.post<User>(url, entity);
  }


  update(entity: User): Observable<User> {

    const url = `${BACKEND_API}/${ENTITY}/${entity.id}/`;

    return this.http.put<User>(url, entity);
  }

  changePassword(entity: User): Observable<User> {

    const url = `${BACKEND_API}/${ENTITY}/changePassword/`;

    return this.http.post<User>(url, entity);
  }



}

// Angular modules
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'lockbin-btn-export',
  templateUrl: './btn-export.component.html',
  styles: [
  ]
})
export class BtnExportComponent {

  // TODO: Posibilidad de convertir esto a una directiva del botón

  @Input() dataToExport: any[] = [];
  @Input() numDataToExport: number = 0;
  @Input() filename: string = '';
  @Input() worksheetName: string = '';
  @Input() buttonLabel: string = 'Exportar';

  @Output() doClick: EventEmitter<any> = new EventEmitter();

  constructor(){}

  public onClick(): void {
    this.doClick.emit();
  }

}

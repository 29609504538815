// Angular modules
import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { OnInit } from '@angular/core';

@Component({
  selector: 'lockbin-cell-basic',
  templateUrl: './cell-basic.component.html',
  styleUrls: ['./cell-basic.component.scss'],
})
export class CellBasicComponent implements OnInit {
  @Input() data: any;
  @Input() hasAction: boolean = false;
  @Output() doClick: EventEmitter<boolean> = new EventEmitter();

  cssClasses: string = '';

  constructor() {
  }

  public ngOnInit(): void {
    if(this.hasAction) {
      this.cssClasses = 'cell-has-link';
    }
  }

  public onClick(): void {
    this.doClick.emit();
  }

}

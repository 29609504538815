
<div class="form-inline flex-nowrap gx-3">
    <div class="form-wrap w-175px">
        <select class="form-select js-select2" data-search="off" data-placeholder="Acciones en lote" [(ngModel)]="selectedOperation">
            <option value="">Acción en lote</option>
            <option *ngFor="let operation of bulkOperations" [value]="operation.alias">{{ operation.title }}</option>
        </select>
    </div>
    <div class="btn-wrap">
        <span class="d-none d-md-block"><button (click)="onClick()" class="btn btn-dim btn-outline-light" [class.disabled]="(this.numElementsChecked===0) || (this.selectedOperation.length === 0)">{{ 'BTN_APPLY' | translate }}</button></span>
        <span class="d-md-none"><button (click)="onClick()" class="btn btn-dim btn-outline-light btn-icon"><em class="icon ni ni-arrow-right"></em></button></span>
    </div>
</div>

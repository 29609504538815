// Angular modules
import { formatDate } from '@angular/common';
import { Component, EventEmitter, Inject, Input, LOCALE_ID, Output } from '@angular/core';
import { OnInit } from '@angular/core';

@Component({
  selector: 'lockbin-cell-date',
  templateUrl: './cell-date.component.html',
  styleUrls: ['./cell-date.component.scss'],
})
export class CellDateComponent implements OnInit {
  @Input() date = '';
  @Input() format = '';
  @Output() doClick: EventEmitter<boolean> = new EventEmitter();

  public locale;
  public dateFormatted = '';

  constructor(@Inject(LOCALE_ID) locale: string) {
    this.locale = locale;
  }

  public ngOnInit(): void {
    if (!this.format) {
      this.format = 'shortDate';
    }
    if (this.date) {
      this.dateFormatted = formatDate(this.date, this.format, this.locale, '');
    }
  }

  public onClick(): void {
    this.doClick.emit();
  }

}

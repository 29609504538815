<div class="card-title-group">

    <lockbin-bulk-operations [bulkOperations]="bulkOperations" [numElementsChecked]="numElementsChecked" class="card-tools" (doClick)="onClickBulkOperation($event)"></lockbin-bulk-operations>

    <div class="card-tools me-n1">
        <ul class="btn-toolbar gx-1">
            <li *ngIf="useSearch">
                <button (click)="openSearch()" class="btn btn-icon search-toggle toggle-search" data-target="search">
                  <div class="dot dot-primary" *ngIf="isSearchApplied"></div>
                  <em class="icon ni ni-search"></em>
                </button>
            </li>
            <li class="btn-toolbar-sep"></li>
            <li>
                <div class="toggle-wrap">
                    <a href="#" class="btn btn-icon btn-trigger toggle" data-target="cardTools"><em class="icon ni ni-menu-right"></em></a>
                    <div class="toggle-content" data-content="cardTools">
                        <ul class="btn-toolbar gx-1">
                            <li class="toggle-close">
                                <a href="#" class="btn btn-icon btn-trigger toggle" data-target="cardTools"><em class="icon ni ni-arrow-left"></em></a>
                            </li>
                            <li *ngIf="false">
                                <div class="dropdown" ngbDropdown>
                                    <button class="btn btn-trigger btn-icon dropdown-toggle" data-bs-toggle="dropdown" ngbDropdownToggle>
                                        <div class="dot dot-primary"></div>
                                        <em class="icon ni ni-filter-alt"></em>
                                    </button>
                                    <div class="filter-wg dropdown-menu dropdown-menu-xl dropdown-menu-end" ngbDropdownMenu>
                                        <div class="dropdown-head">
                                            <span class="sub-title dropdown-title">Filter Users</span>
                                        </div>
                                        <div class="dropdown-body dropdown-body-rg">
                                            <div class="row gx-6 gy-3">
                                                <div class="col-6">
                                                    <div class="custom-control custom-control-sm custom-checkbox">
                                                        <input type="checkbox" class="custom-control-input" id="hasBalance">
                                                        <label class="custom-control-label" for="hasBalance"> Have Balance</label>
                                                    </div>
                                                </div>
                                                <div class="col-6">
                                                    <div class="custom-control custom-control-sm custom-checkbox">
                                                        <input type="checkbox" class="custom-control-input" id="hasKYC">
                                                        <label class="custom-control-label" for="hasKYC"> KYC Verified</label>
                                                    </div>
                                                </div>
                                                <div class="col-6">
                                                    <div class="form-group">
                                                        <label class="overline-title overline-title-alt">Role</label>
                                                        <select class="form-select js-select2">
                                                            <option value="any">Any Role</option>
                                                            <option value="investor">Investor</option>
                                                            <option value="seller">Seller</option>
                                                            <option value="buyer">Buyer</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-6">
                                                    <div class="form-group">
                                                        <label class="overline-title overline-title-alt">Status</label>
                                                        <select class="form-select js-select2">
                                                            <option value="any">Any Status</option>
                                                            <option value="active">Active</option>
                                                            <option value="pending">Pending</option>
                                                            <option value="suspend">Suspend</option>
                                                            <option value="deleted">Deleted</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="form-group">
                                                        <button type="button" class="btn btn-secondary">Filter</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="dropdown-foot between">
                                            <a class="clickable" href="#">Reset Filter</a>
                                            <a href="#">Save Filter</a>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="dropdown" ngbDropdown #paginationItemsDrop="ngbDropdown">
                                    <button class="btn btn-trigger btn-icon dropdown-toggle" data-bs-toggle="dropdown" ngbDropdownToggle>
                                        <em class="icon ni ni-setting"></em>
                                    </button>
                                    <div class="dropdown-menu dropdown-menu-xs dropdown-menu-end" ngbDropdownMenu>
                                        <ul class="link-check">
                                            <li><span>Mostrar</span></li>
                                            <li *ngFor="let numItems of paginationItemsPerPage" [class.active]="numItems === paginationItemsPerPageSelected">
                                              <a href="#" (click)="doChangePaginationItemsPerPage($event, numItems); paginationItemsDrop.close();">{{ numItems }}</a>
                                            </li>
                                        </ul>
                                        <ul class="link-check">
                                            <li><span>Orden</span></li>
                                            <li *ngFor="let order of ['ASC', 'DESC']" (click)="doChangePaginationSortOrder($event, order); paginationItemsDrop.close();" [class.active]="order === paginationSortOrder">
                                              <a href="#">{{order}}</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</div>
<div class="card-search search-wrap" data-search="search" lockbinClickOutside *ngIf="useSearch" [class.active]="isSearchOpen" (clickOutside)="clickOutsideSearch($event)" (keyup.Escape)="closeSearch()">
    <div class="card-body">
        <div class="search-content">
            <button (click)="closeSearch()" class="search-back btn btn-icon toggle-search" data-target="search" [class.active]="isSearchOpen"><em class="icon ni ni-arrow-left"></em></button>
            <input #headerSearchText type="text" class="form-control border-transparent form-focus-none" [placeholder]="searchPlaceholder" (keyup.enter)="onDoSearch()">
            <button (click)="onDoSearch()" class="search-submit btn btn-icon"><em class="icon ni ni-search"></em></button>
        </div>
    </div>
</div>

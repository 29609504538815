// Angular Modules
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

// External Modules
import { forkJoin, map, Observable } from 'rxjs';

// Models
import { QueryParameters, QueryResults } from '@models/index';
import { Country } from '@configuration/countries/models/index';

// Services
import { HttpQueryUtils } from '@services/http-query-utils.service';

const BACKEND_API = `${environment.apiBaseUrl}`;
const ENTITY = 'country'


@Injectable({
  providedIn: 'root'
})
export class CountryService {

  constructor(
    private http: HttpClient,
    public httpQueryUtils: HttpQueryUtils) { }

  // get httpParams() {
  //   return new HttpParams()//.set('fields', 'name,capital,alpha2Code,flags.png,population');
  // }


  find(queryParameters: QueryParameters): Observable<QueryResults<Country>> {

    const url = `${BACKEND_API}/${ENTITY}/`;

    const queryResults$ =  this.httpQueryUtils.executeQuery<Country>( url, queryParameters);

    return  queryResults$;
  }


  delete(id: number): Observable<any> {

    const url = `${BACKEND_API}/${ENTITY}/${id}/`;

    return this.http.delete(url);
  }


  deleteBulk(ids: Array<number>): Observable<any> {

    const colObservablesToDelete= [];

    for (const id of ids) {
      colObservablesToDelete.push(this.delete(id));
    }

    return forkJoin( colObservablesToDelete );
  }


  add(entity: Country): Observable<Country> {

    const url = `${BACKEND_API}/${ENTITY}/`;

    return this.http.post<Country>(url, entity);
  }


  update(entity: Country): Observable<Country> {

    const url = `${BACKEND_API}/${ENTITY}/${entity.id}/`;

    return this.http.put<Country>(url, entity);
  }


  findById(id: number): Observable<Country> {

    const url = `${BACKEND_API}/${ENTITY}/${id}/`;
    return this.httpQueryUtils.executeQuery<Country>(url, {})
            .pipe(
                map(result => result.items[0])
              )
  }

}

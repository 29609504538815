// Angular modules
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { OnInit } from '@angular/core';

@Component({
  selector: 'lockbin-cell-user-card',
  templateUrl: './cell-user-card.component.html',
  styleUrls: ['./cell-user-card.component.scss'],
})
export class CellUserCardComponent implements OnInit {
  @Input() user: any;
  @Input() status?: string;
  @Output() doClick: EventEmitter<boolean> = new EventEmitter();

  public avatarInitial = '';

  constructor() {
  }

  public ngOnInit(): void {
    this.avatarInitial = this.user.name.charAt(0).toUpperCase()

    const words = this.user.name.split(' ');
    if (words.length > 0) {
      this.avatarInitial += words[1].charAt(0).toUpperCase()
    }
  }

  public onClick(): void {
    this.doClick.emit();
  }

}

import {
  Component,
  OnInit,
  Input,
  AfterViewInit,
  ElementRef,
  ViewEncapsulation,
  NgZone,
  OnDestroy,
  HostListener,
  HostBinding,
} from '@angular/core';

import SimpleBar, { SimpleBarOptions } from 'simplebar-core';

@Component({
  selector: 'ngx-simplebar',
  host: {
    'data-simplebar': 'init',
  },
  templateUrl: './simplebar-angular.component.html',
  styleUrls: [
    // '../../../simplebar-core/src/simplebar.css',
    './simplebar-angular.component.scss',
  ],
  encapsulation: ViewEncapsulation.None,
})
export class SimplebarAngularComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input('options') options: Partial<SimpleBarOptions> = {};

  // Establece si se va a calcular la altura de forma dinámica en función de la altura de la ventana.
  @Input() dynamicHeight: boolean = true;

  // El valor en px de la altura que debe restar de la altura total de la ventana.
  @Input() heightDecrement: number = 364;

  // El valor mínimo de la altura que debe tener el bloque de scroll Simplebar
  @Input() minHeight: number = 340;

  // Captura el evento de scroll de ventana y recalcula la altura del bloque de scroll
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (this.dynamicHeight) {
      this.calculateHeight(window);
    }
  }

  // Establece con estilos CSS inline los valores de altura y altura mínima
  @HostBinding('style.height.px') height!: number;
  @HostBinding('style.minHeight.px') minHeightPx!: number;

  // Propiedades de Simplebar
  elRef: ElementRef;
  SimpleBar: any;
  ariaLabel: string;

  constructor(elRef: ElementRef, private zone: NgZone) {
    this.elRef = elRef;
    this.ariaLabel =
      this.options.ariaLabel || SimpleBar.defaultOptions.ariaLabel;
  }

  ngOnInit() {

    // TODO: Revisar si hay que establecer el cálculo de la altura siempre.
    this.calculateHeight(window);

    this.minHeightPx = this.minHeight;

  }

  ngAfterViewInit(): void {
    this.zone.runOutsideAngular(() => {
      this.SimpleBar = new SimpleBar(
        this.elRef.nativeElement,
        this.options || {}
      );
    });
  }

  /**
   * Establece la altura del bloque de scroll en función de la altura de la ventana.
   *
   * @param window El objeto ventana que usamos para calcular la altura.
   */
  calculateHeight(window: any) {
    if (this.dynamicHeight) {
      this.height = (window.innerHeight - this.heightDecrement);
    }
  }

  ngOnDestroy() {
    if (this.SimpleBar) {
      this.SimpleBar.unMount();
      this.SimpleBar = null;
    }
  }
}

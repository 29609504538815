export enum PREDEFINED_ROLES {
  ROLE_CUSTOMER= 'ROLE_CUSTOMER',
  ROLE_MAINTENANCE= 'ROLE_MAINTENANCE',
  ROLE_MANAGER= 'ROLE_MANAGER',
  ROLE_PUBLIC= 'ROLE_PUBLIC',
  ROLE_ROOT= 'ROLE_ROOT',
  ROLE_SUBCONTRACT= 'ROLE_SUBCONTRACT',
}


export function getPredefinedRoles(): { value: string, name: string }[] {
  return Object.keys(PREDEFINED_ROLES)
    .map((key:string) => { return { value: PREDEFINED_ROLES[key as keyof typeof PREDEFINED_ROLES], name: key }; });
}

// Angular modules
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { OnInit } from '@angular/core';

@Component({
  selector: 'lockbin-cell-status',
  templateUrl: './cell-status.component.html',
  styleUrls: ['./cell-status.component.scss'],
})
export class CellStatusComponent implements OnInit {
  @Input() status = '';
  @Input() statusCssClass = '';
  @Output() doClick: EventEmitter<boolean> = new EventEmitter();

  constructor() {}

  public ngOnInit(): void {}

  public onClick(): void {
    this.doClick.emit();
  }

}

export enum BUS_EVENT_TYPE {

  // Loading components
  LOAD_HEADER_DATA = 'component.loadHeaderData',

  LOAD_HEADER_SUBMENU = 'component.loadHeaderSubmenu',

  DEVICE_IMPORT_STEP_1_DONE        = 'device.importStep1Done',
  DEVICE_IMPORT_STEP_1_FAIL        = 'device.importStep1Fail',
  DEVICE_SERIALS_UPDATED           = 'device.serialsUpdated',
  DEVICE_CONFIGURATION_APPLIED     = 'device.configurationApplied',
  DEVICE_VALIDATED_SETTED          = 'device.validatedSetted',
  DEVICE_REQUIRE_REVISION_SETTED   = 'device.requireRevisionSetted',
  DEVICE_MAL_FUNCTION_SETTED       = 'device.malFunctionSetted',
  DEVICE_MATCH_SETTED              = 'device.matchSetted',
  CONF_TEMPLATE_SELECTED           = 'confTemplate.selected',


  CARD_IMPORT_STEP_1_DONE        = 'card.importStep1Done',
  CARD_IMPORT_STEP_1_FAIL        = 'card.importStep1Fail',

  DEVICES_CONFIGURATION_PROFILE_APPLIED         = 'devices.configurationProfileApplied',
  EQUIPMENTS_CONFIGURATION_PROFILE_APPLIED      = 'equipments.configurationProfileApplied',
  EQUIPMENT_GROUP_CONFIGURATION_PROFILE_APPLIED = 'equipmentgroup.configurationProfileApplied',

  IS_SUBELEMENT_BEING_EDITED= 'subelement.beingEdited'

}


<ngb-alert
  *ngIf="content"
  [type]="type"
  [dismissible]="dismissible"
  [class.alert-fill]="isFilled"
  [class.alert-icon]="icon.length > 0"
  (closed)="hideAlert()">
    <em class="icon ni" [ngClass]="icon.length > 0 ? 'ni-' + icon : ''" *ngIf="icon.length > 0"></em>
    <span [innerHTML]="content" *ngIf="content"></span>
</ngb-alert>

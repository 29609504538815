// Angular modules
import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { StoreService } from '../../services/store.service';
import { EventBusService } from '@services/event-bus.service';
import { ActivatedRoute } from '@angular/router';
import { BUS_EVENT_TYPE } from '@enums/bus-event-type.enum';
import { NGXLogger } from 'ngx-logger';
import { IHeaderData } from '@models/header-data.interface';


@Component({
  template: '',
})
export class BaseComponent implements OnInit {


  // -------------------------------------------------------------------------------
  // NOTE Constructor  -------------------------------------------------------------
  // -------------------------------------------------------------------------------
  constructor(
    protected location: Location,
    protected titleService: Title,
    protected storeService: StoreService,
    protected logger: NGXLogger,
    protected activatedRoute: ActivatedRoute,
    protected eventBusService: EventBusService,
  ) {

  }

  ngOnInit(): void {

    this.logger.debug('ngOnInit from BaseComponent');

    // Llama a la función que establece los valores de la cabecera
    this.setHeaderContent();

  }

  // -------------------------------------------------------------------------------
  // #region Utils

  private setHeaderContent() {

    // Buscamos en las propiedades "data" de los routes desde el actual hacia arriba.
    let snapshot = this.activatedRoute.snapshot;
    let headerData: IHeaderData | boolean = false;

    // Buscamos que la propiedad "data" tenga una propiedad "headerData" con información de la cabecera

    console.log(snapshot.data);
    console.log(snapshot.data['headerData']);


    if (Object.prototype.hasOwnProperty.call(snapshot.data, 'headerData')) {

      headerData = snapshot.data['headerData'];

    } else {

      while(snapshot.parent) {

        console.log(snapshot.parent.data);

        if (Object.prototype.hasOwnProperty.call(snapshot.parent.data, 'headerData')) {
          headerData = snapshot.parent.data['headerData'];
          console.log('EXISTE', headerData);

          break;
        }
        snapshot = snapshot.parent;
      }
    }

    if (headerData) {
      console.log('EMITO', headerData);

      this.eventBusService.emit({ name: BUS_EVENT_TYPE.LOAD_HEADER_DATA, value: headerData });
    }

    if (Object.prototype.hasOwnProperty.call(snapshot.data, 'idParent')) {

      console.log('---------------------------');
      console.log(snapshot.data['idParent']);

      this.eventBusService.emit({ name: BUS_EVENT_TYPE.LOAD_HEADER_SUBMENU, value: snapshot.data['idParent'] });

    }

  }

  protected errorInQuery() {
    this.storeService.setIsLoading(false);
    this.storeService.setIsError(true);
  }

  protected beforeStartQuery() {
    this.storeService.setIsLoading(true);
    this.storeService.setIsError(false);
  }

  protected goBack() {
    this.location.back();
  }
  //#endregion
  // -------------------------------------------------------------------------------

}

<div class="nk-header-section">
  <div class="nk-header-section-background">
    <img class="nk-header-section-background-image" [src]="backgroundSrc" alt="">
  </div>
  <div class="nk-header-section-content">
    <div class="d-flex justify-content-between">
      <h2 class="nk-header-section-title">{{ title }}</h2>
      <svg-icon class="nk-header-section-logo" src="assets/img/logo-fv.svg" [svgStyle]="{ 'width.px': 220 }"></svg-icon>
    </div>
  </div>
</div>

<div class="nk-header-submenu" *ngIf="subMenuItems">
  <div class="nk-header-submenu-items">
    <a *ngFor="let item of subMenuItems" class="nk-header-submenu-link" [routerLink]="item.route" routerLinkActive="current-route">
      <span>{{ item.title }}</span>
    </a>
  </div>
</div>

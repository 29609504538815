// Models
import { Country } from "@configuration/countries/models";
import { QueryFilter } from "@models/api-request.model";
import { Exporter } from "@models/index";

export class Region {

  id:           number = 0;
  version:      number | undefined;
  name:         string | undefined;
  code:         string | undefined;
  countryId:    number = 0;
  country:      Country = new Country();

}

export class RegionFilter implements QueryFilter {

  name:       string | undefined;
  code?:      string | undefined;

}

export class RegionExporter implements Exporter {

  name;
  code;
  country;


  constructor() {

    this.name = {
      nameToShow: 'Nombre'
    };
    this.code = {
      nameToShow: 'Codigo'
    };

    this.country = {
      name: {
        nameToShow: 'País'
      }
    }

  }
}

// Angular modules
import { Component, inject } from '@angular/core';
import { OnInit } from '@angular/core';
import { Router } from '@angular/router';

// Internal modules

@Component({
  selector: 'lockbin-layout-content',
  templateUrl: './layout-content.component.html',
  styleUrls: ['./layout-content.component.scss'],
})
export class LayoutContentComponent implements OnInit {

  constructor
    (
      private router: Router,
    ) {

  }

  public ngOnInit(): void {

  }

}

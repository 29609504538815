// Angular Modules
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

// External Modules
import { forkJoin, map, Observable, of, switchMap } from 'rxjs';

// Models
import { QueryParameters, QueryResults } from '@models/index';
import { Customer } from '@customers/customers-mgmt/models/index';

// Services
import { HttpQueryUtils } from '@services/http-query-utils.service';
import { CityService } from '@configuration/cities/services/city.service';

const BACKEND_API = `${environment.apiBaseUrl}`;
const ENTITY = 'customer'


@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  constructor(
    private http: HttpClient,
    public httpQueryUtils: HttpQueryUtils,
    private cityService: CityService) { }

  // get httpParams() {
  //   return new HttpParams()//.set('fields', 'name,capital,alpha2Code,flags.png,population');
  // }


  find(queryParameters: QueryParameters): Observable<QueryResults<Customer>> {

    const url = `${BACKEND_API}/${ENTITY}/`;


    let queryResults$ =  this.httpQueryUtils.executeQuery<Customer>( url, queryParameters )
      .pipe(
        switchMap((queryResults: QueryResults<Customer>) => {

          // return empty Observable in case of no items
          if (queryResults.items.length === 0) return of(queryResults);

          const requestCity = queryResults.items.map((customer: Customer) => this.cityService.findById(customer.cityId));
          return forkJoin(requestCity)
            .pipe(
              map(customDataArray => {
                for (let i = 0; i < queryResults.items.length; i++) {
                  queryResults.items[i].city = customDataArray[i];
                }
                return queryResults;
              })
            );
        })
      )

    return queryResults$;
  }


  delete(id: number): Observable<any> {

    const url = `${BACKEND_API}/${ENTITY}/${id}/`;

    return this.http.delete(url);
  }


  deleteBulk(ids: Array<number>): Observable<any> {

    const colObservablesToDelete= [];

    for (const id of ids) {
      colObservablesToDelete.push(this.delete(id));
    }

    return forkJoin( colObservablesToDelete );
  }


  add(entity: Customer): Observable<Customer> {

    const url = `${BACKEND_API}/${ENTITY}/`;

    return this.http.post<Customer>(url, entity);
  }


  update(entity: Customer): Observable<Customer> {

    const url = `${BACKEND_API}/${ENTITY}/${entity.id}/`;

    return this.http.put<Customer>(url, entity);
  }


  findById(id: number): Observable<Customer> {

    const url = `${BACKEND_API}/${ENTITY}/${id}/`;
    return this.httpQueryUtils.executeQuery<Customer>(url, {})
            .pipe(
                map(result => result.items[0])
              )
  }

}

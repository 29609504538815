// Angular Modules
import { EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Component, ElementRef, QueryList, Renderer2, ViewChild, ViewChildren } from '@angular/core';
import { Location } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

// External modules
import Swal from 'sweetalert2';
import { Observable, Subject, forkJoin, takeUntil } from 'rxjs';

// Base class
import { BaseComponent } from './base';

// Environments
import { environment } from '@env/environment';

// Components
import { HeaderToolsComponent } from '@datalist/header-tools/header-tools.component';
import { PaginationComponent } from '@datalist/pagination/pagination.component';
import { BtnExportComponent } from '@datalist/btn-export/btn-export.component';
import { AlertComponent } from '@blocks/alert/alert.component';

// Helpers
import { DataListConfigurerHelper } from '@helpers/datalist-configurer.helper';
import { ExcelExporterHelper } from '@excel-exporter/excel-exporter.helper';
import { ExporterHelper } from '@helpers/exporter.helper';
import { StorageHelper } from '@helpers/storage.helper';

// Enums
import { StorageKey } from '@enums/storage-key.enum';

// Modal
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ModalWrapperComponent } from '@modals/modal-wrapper/modal-wrapper.component';

// Models
import { FilterSpecific, QueryFilter, QueryParameter, QueryResults } from '@models/api-request.model';
import { DataList, DataListHeader, DataListItem, DataListOperation, StatusCssClass } from '@models/datalist.model';
import { Exporter } from '@models/exporter.model';

// Services
import { StoreService } from '@services/store.service';
import { NGXLogger } from 'ngx-logger';
import { ActivatedRoute, Router } from '@angular/router';
import { SearchStrategy } from '@enums/search-strategy.enum';
import { BaseFilterComponent } from './base-filter.component';
import { EventBusService } from '@services/event-bus.service';


@Component({
  template: ''
})
export class BaseListComponent<T> extends BaseComponent implements OnInit, OnDestroy {

  @ViewChild(AlertComponent) alertComponent!: AlertComponent;
  @Output() finishDeleteOperation = new EventEmitter<boolean>();
  @Output() finishGenericOperation = new EventEmitter<boolean>();
  @Output() finishDeleteBulkOperation = new EventEmitter<boolean>();


  dataListWrapper: DataList<DataListItem<T>> = new DataList();
  dataItems: Array<DataListItem<T>> = [];
  preprocessedDataItems: Array<DataListItem<any>> = [];
  dataRawItems: Array<T> = [];
  dataItemsToExport: Array<any> = [];

  lastDeletedItemId!: number;

  // dataItemsFiltered: Array<DataListItem<T>> = []; // TODO: Es necesario?

  currentPage = 1;
  totalItems = -1;
  itemsPerPage = -1;
  sortOrder = 'DESC';

  searchString = '';
  specificFilter!: FilterSpecific;

  routeToAdd = '';
  routeToEdit = '';


  paramsWithFilterSubject = new Subject<any>();
  obsFindByParams$!: Observable<any>;
  obsFindByFilter$!: Observable<any>;
  paramsWithFilter!: QueryParameter;
  searchStategy!: SearchStrategy;
  searchStategyForCollections!: SearchStrategy;

  baseFilterComponent!: BaseFilterComponent<T>;


  klassName: any;

  statusCssClasses!: StatusCssClass;

  @ViewChild(PaginationComponent) paginationComponent!: PaginationComponent;
  @ViewChild(HeaderToolsComponent) headerToolsComponent!: HeaderToolsComponent<T>;

  @ViewChild(BtnExportComponent) btnExportComponent!: BtnExportComponent;
  fileNameExportedData = 'Registros'; //TODO: Translate
  workSheetName = 'Hoja 1'; //TODO: Translate

  checkboxesIdsChecked: number[] = [];
  @ViewChildren('rowCheckbox') checkboxes!: QueryList<ElementRef>;
  numElementsChecked: number = 0;

  dataListConfigurerHelper!: DataListConfigurerHelper<T>;

  exporter!: Exporter;

  // Modal
  @ViewChild(ModalWrapperComponent) modalWrapperComponent!: ModalWrapperComponent;
  componentModal: any;
  componentModalData: any;
  modalData = {
    title: 'TITLE',
    useHeader: true,
    closable: true,
  };



  protected ngUnsubscribe = new Subject<boolean>();
  // -------------------------------------------------------------------------------



  // -------------------------------------------------------------------------------
  //#region Search & Query
  // -------------------------------------------------------------------------------

  protected initParamsWithFilter() {
    this.paramsWithFilter = this.getParamsWithFilter();
    this.paramsWithFilterSubject.next(this.paramsWithFilter);
  }

  protected doGetAll() {

    if (!this.storeService.getIsLoading()) {

      this.paramsWithFilter = this.getParamsWithFilter();
      this.paramsWithFilterSubject.next(this.paramsWithFilter);


      this.find(this.obsFindByParams$);
    }
  }


  protected doSearchByParams(searchText: string) {

    this.searchString = searchText;
    this.headerToolsComponent.isSearchApplied = searchText.length > 0;

    this.resetPagination();

    this.paramsWithFilter = this.getParamsWithFilter();
    this.paramsWithFilterSubject.next(this.paramsWithFilter);


    if (!this.storeService.getIsLoading()) {

      this.setOrUpdateParamsWithFilterInLocalStorage(this.paramsWithFilter);


      this.find(this.obsFindByParams$);

    }

  }

  protected doSearchBySpecificFilter() {

    this.resetPagination();

    if (!this.storeService.getIsLoading()) {

      this.paramsWithFilter = this.getParamsWithFilter();
      this.paramsWithFilterSubject.next(this.paramsWithFilter);


      this.setOrUpdateParamsWithFilterInLocalStorage(this.paramsWithFilter);

      this.find(this.obsFindByFilter$);

    }
  }

  protected doChangePaginationItemsPerPage(numItems: number) {

    this.itemsPerPage = numItems; //used for query's service

    this.headerToolsComponent.dataListHeader.pagination.itemsPerPage = numItems; //used for interface

    // Goto first page
    this.doChangePagination(1);
    // TODO: Igual se puede cambiar para que se mantenga en el rango de página que estaba
  }

  protected doChangePaginationSortOrder(sortOrder: string) {
    this.sortOrder = sortOrder;

    // Goto first page
    this.doChangePagination(1);
    // TODO: Igual se puede cambiar para que se mantenga en el rango de página que estaba
  }

  protected doChangePagination(page: number) {

    if (!this.storeService.getIsLoading()) {

      this.logger.info('Change to PAGE:', page);
      this.currentPage = page;
      this.paramsWithFilter = this.getParamsWithFilter();
      this.paramsWithFilterSubject.next(this.paramsWithFilter);

      // executes a "find" depending on with filter is being used (params filter o specific filter)
      if (this.headerToolsComponent?.isSearchApplied)
        this.find(this.obsFindByParams$);

      else if (this.baseFilterComponent?.isSpecificFilterApplied)
        this.find(this.obsFindByFilter$);

      else
        this.find(this.obsFindByParams$);



    }
  }

  //#endregion
  // -------------------------------------------------------------------------------


  // -------------------------------------------------------------------------------
  // #region Constructor & Init & Destroy ------------------------------------------
  // -------------------------------------------------------------------------------

  constructor(
    protected override location: Location,
    protected override titleService: Title,
    protected override storeService: StoreService,
    protected override logger: NGXLogger,
    protected override activatedRoute: ActivatedRoute,
    protected override eventBusService: EventBusService,
    protected modalService: NgbModal,
    protected renderer: Renderer2,
    protected translateService: TranslateService,
    protected router: Router,
  ) {
    super(location, titleService, storeService, logger, activatedRoute, eventBusService);
  }


  override ngOnInit(): void {
    this.klassName = this.constructor.name;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
  //#endregion
  // -------------------------------------------------------------------------------


  // -------------------------------------------------------------------------------
  // -------------------------------------------------------------------------------
  /**
   * Transform original data from EP into data compatible with 'items' property of datalist object
   * @param originalData original data that must be typed
   * @returns
   */
  protected transformEntityDataToDataListItem<T>(originalData: any[]): Array<DataListItem<T>> {
    const toret: Array<DataListItem<T>> = [];

    if (originalData?.length > 0) {
      for (const item of originalData) {
        const li: DataListItem<T> = {
          id: item.id || 0,
          content: item,
        };
        toret.push(li);
      }
    }

    return toret;
  }


  /**
   * Preprocess data. It can be useful when rendered data are diferents from original and need any function to process.
   * For example, you have 2 field to store a time (hour and minutes) and you need show a string like hh:mm.
   *
   * This method must be overrited in any page
   *
   * @param dataListItems Original
   * @returns Data preprocessed
   */
  protected preprocessData(): Array<DataListItem<T>> {

    return this.preprocessedDataItems = this.dataItems;

  }


    /**
   * Preprocess data to export (similar to previous, but for exporting data). It can be useful when rendered data are diferents from original and need any function to process.
   * For example, you have 2 field to store a time (hour and minutes) and you need show a string like hh:mm.
   *
   * This method must be overrited in any page
   *
   * @param dataRawItems Original
   * @returns Data preprocessed
   */
    protected preprocessDataToExport(dataRawItems: Array<T>): Array<T> {

      return dataRawItems;

    }

  // -------------------------------------------------------------------------------
  //#region Setup Base class emitters
  // -------------------------------------------------------------------------------
  /**
   * Setup instruccions to do when operations are exectuted in base class
   */
  protected setupEventForBaseClassOperations() {

    this.setupEventForFinishDeleteOperation();
    this.setupEventForFinishDeleteBulkOperation();
  }


  protected setupEventForFinishDeleteOperation() {
    this.finishDeleteOperation.subscribe(resultOperation => {

      if (resultOperation) {

        this.doGetAll();

      }
    })
  }


  protected setupEventForFinishDeleteBulkOperation() {
    this.finishDeleteBulkOperation.subscribe(resultOperation => {

      if (resultOperation) {

        this.doGetAll();

      }
    })
  }
  //#endregion
  // -------------------------------------------------------------------------------


  // -------------------------------------------------------------------------------
  // #region Search & Query
  // -------------------------------------------------------------------------------

  protected setupHeaderSearch() {

    const queryParamsInLocalStorage: Map<string, QueryParameter> = StorageHelper.getItem(StorageKey.QUERY_PARAMS);

    this.searchString = '';
    this.headerToolsComponent.isSearchApplied = false;

    if (queryParamsInLocalStorage) {

      const queryParamsInLocalStorageKlass = queryParamsInLocalStorage.get(this.klassName);

      if (queryParamsInLocalStorageKlass && queryParamsInLocalStorageKlass.filterByParams) {

        this.searchString = queryParamsInLocalStorageKlass.filterByParams.toString();
        this.headerToolsComponent.isSearchApplied = true;
      }
    }
  }


  protected setOrUpdateParamsWithFilterInLocalStorage(paramsWithFilter: QueryFilter) {
    let filtersInLocalStorage: Map<string, QueryParameter> = StorageHelper.getItem(StorageKey.QUERY_PARAMS);

    if (!filtersInLocalStorage)
      filtersInLocalStorage = new Map<string, QueryParameter>();

    filtersInLocalStorage.set(this.klassName, paramsWithFilter)
  }


  protected getQueryParameters(sortField: string = 'id'): QueryParameter {
    const queryParams: QueryParameter = {
      pageNo: this.currentPage > 0 ? this.currentPage - 1 : 0,
      pageSize: this.itemsPerPage ? this.itemsPerPage : 2,
      sort: `${sortField},${this.sortOrder}`,
      // sort: `${sortField}-${this.sortOrder}`,

    };

    return queryParams;
  }


  protected getParamsWithFilter(): QueryParameter {
    const queryParams = this.getQueryParameters();

    if (this.searchString?.length > 0) {
      queryParams.filterByParams = {
        serial: this.searchString, // TODO: Se modifica esto para el caso de Equipment, habría que usar "FilterSpecific", revisar.
        name: this.searchString,
      }

    }

    if (this.specificFilter) {
      queryParams.filterBySpecificFilter = this.specificFilter;
    }

    return queryParams;
  }


  protected find(obs$: Observable<QueryResults<T>>) {

    this.beforeStartQuery();

    obs$
      .pipe(
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe({
        next: (queryResults: QueryResults<T>) => {

          this.storeService.setIsLoading(false);

          // pagination
          this.storeService.setNumDataToExport(0);
          this.totalItems = queryResults.totalItems ?? 0;

          // data to be listed (entities with wrapper)
          this.dataListWrapper.data.items = this.transformEntityDataToDataListItem(queryResults.items);
          this.dataItems = this.dataListWrapper.data.items;
          this.dataItems = this.preprocessData();

          // Mostramos los dataItems obtenidos si estoy en modo Debug
          this.logger.debug('BaseListComponent::find', this.dataItems);

          // data prepared to be exported (only entities)
          this.dataRawItems = queryResults.items;
          this.dataRawItems = this.preprocessDataToExport(this.dataRawItems);
        },
        error: (e) => {

          this.errorInQuery();

          Swal.fire({
            icon: 'error',
            title: 'Importante',
            text: 'No se ha podido recuperar la información solicitada',
          })

          this.totalItems = 0;
          this.storeService.setNumDataToExport(this.totalItems);
          this.dataListWrapper.data.items = [];
          this.dataItems = [];
          this.dataRawItems = [];

          this.logger.error(e);
        },
        complete: () => {
          this.storeService.setIsLoading(false);
          this.doWhenFinishFind();
          setTimeout(
            () => {
              this.storeService.setNumDataToExport(this.totalItems)
              this.logger.info('complete---------')

            }, 2000

          )
        }
      });
  }
  protected doWhenFinishFind() {
  }

  //#endregion
  // -------------------------------------------------------------------------------


  // -------------------------------------------------------------------------------
  // #region DataList setup
  // -------------------------------------------------------------------------------

  /**
   * Get setup for datalist wrapper settings properties like useCheckbox, userSearch, etc.
   * @returns
   */
  protected getDefaulDataListWrapperBasicSetup(): DataList<DataListItem<T>> {
    return this.dataListConfigurerHelper.getDefaulDataList();
  }

  /**
   * Get setup for datalist header: itemsPerPage, itemsPerPageRange, paginationMode, etc.
   * @returns a DataListHeader with the header setup
   */
  protected getDatataListHeaderSetup(): DataListHeader {
    return this.dataListConfigurerHelper.getDefaulDataListHeader();
  }

  /**
   * Get row operations
   * @returns a DataListOperation array with row operations
   */
  protected getRowOperationsToShow(): DataListOperation[] {
    return this.dataListConfigurerHelper.getDefaultDataListOperations();
  }

  /**
   * Get bulk operations
   * @returns a DataListOperation array with bulk operations
   */
  protected getBulkOperationsToShow(): DataListOperation[] {
    return this.dataListConfigurerHelper.getDefaultBulkOperations();
  }
  //#endregion
  // -------------------------------------------------------------------------------


  // -------------------------------------------------------------------------------
  // #region Pagination
  // -------------------------------------------------------------------------------

  protected initPagination(itemsPerPage: number) {
    if (itemsPerPage)
      this.itemsPerPage = itemsPerPage;
  }

  protected resetPagination() {
    this.currentPage = 1;
  }
  //#endregion
  // -------------------------------------------------------------------------------


  // -------------------------------------------------------------------------------
  // #region Export Setup
  // -------------------------------------------------------------------------------

  protected doExportClick(event: any) {

    this.doExport(event);
  }

  protected doExport(event: any) {

    const numRecordsToExport = this.getNumRecordsToExport();

    if (numRecordsToExport > this.dataListWrapper.maxNumberRecordsToExport) {

      Swal.fire({
        title: `${this.translateService.instant('ALERT_EXPORT_MAX_RECORDS')} (${numRecordsToExport})`,
        text: this.translateService.instant('ALERT_CONFIRM_TEXT'),
        showClass: { popup: 'animated fadeInDown ', },
        showCancelButton: true,
        confirmButtonText: this.translateService.instant('BTN_CONTINUE'),
        cancelButtonText: this.translateService.instant('BTN_CANCEL')
      }).then((result) => {
        if (result.isConfirmed) {
          this.exportToExcel();
        }
      })

    } else {

      this.exportToExcel();

    }

  }

  private exportToExcel() {

    const colItemsThatCanBeExported = this.getColThanCanBeExported();

    this.dataItemsToExport = ExporterHelper.getCollectionToExport(colItemsThatCanBeExported, this.exporter);

    this.storeService.setIsLoading(true);

    try {
      ExcelExporterHelper.exportToExcel(
        this.dataItemsToExport,
        this.fileNameExportedData,
        this.workSheetName
      );

      this.storeService.setIsLoading(false);

    } catch (error) {
      this.logger.error(error);
      this.storeService.setIsLoading(false);

      this.showMessages(
        this.translateService.instant('LIST_ERROR'),
        'danger',
        5000
      );

    }

  }


  // returns the number of seleted items or dataRawItems.length if no item is selected
  private getNumRecordsToExport() {
    return this.checkboxesIdsChecked.length === 0 ? this.dataRawItems.length : this.checkboxesIdsChecked.length;
  }

  // returns items that can be exported:
  //  - if no item selected: returns dataRawItems
  //  - else returns dataRawItems that are checked
  private getColThanCanBeExported(): any[] {

    if (this.checkboxesIdsChecked.length === 0) return this.dataRawItems;

    else return this.dataRawItems.filter((item: any) => this.checkboxesIdsChecked.includes(item.id))

  }
  //#endregion
  // -------------------------------------------------------------------------------


  // -------------------------------------------------------------------------------
  // #region RowOperations
  // -------------------------------------------------------------------------------

  protected doClick() {
    this.logger.info('Hizo click');
  }

  protected delete(obs$: any) {

    this.beforeStartQuery();

    obs$
      .pipe(
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe({
        next: () => {

          this.storeService.setIsLoading(false);
          this.logger.info('Eliminando en delete');

          this.finishDeleteOperation.emit(true);

        },
        error: (e: any) => {

          this.errorInQuery();

          this.finishDeleteOperation.emit(false);

          Swal.fire({
            icon: 'error',
            title: 'Importante',
            text: 'No se ha podido eliminar el registro',
          })
          this.logger.error(e);
        },
        complete: () => {
          this.logger.info('complete')
        }
      });

  }

  protected showModalQuickView(item: any, modalOptions?: NgbModalOptions) {

    const defaultModalOptions: NgbModalOptions = {
      size: 'md',
      modalDialogClass: '',
      windowClass: '',
    }
    const selectedModalOptions = modalOptions || defaultModalOptions;


    this.beforeStartQuery();

    this.setModalConfiguration(item, selectedModalOptions)

    this.storeService.setIsLoading(false);
  }

  protected specificOperation(obs$: any) {

    this.beforeStartQuery();

    obs$
      .pipe(
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe({
        next: (res: any) => {

          this.storeService.setIsLoading(false);
          this.logger.info('Ejecutando una operación genérica');

          this.doWhenSpecificOperationFinish(res);

          this.finishGenericOperation.emit(true);

        },
        error: (e: any) => {

          this.errorInQuery();

          this.finishGenericOperation.emit(false);

          Swal.fire({
            icon: 'error',
            title: 'Importante',
            text: 'No se ha podido realizar la operación',
          })
          this.logger.error(e);
        },
        complete: () => {
          this.logger.info('complete')
        }
      });

  }

  protected specificOperations(obss$: any[]) {

    this.beforeStartQuery();

    forkJoin([obss$])
      .pipe(
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe({
        next: (res: any) => {

          this.storeService.setIsLoading(false);
          this.logger.info('Ejecutando una operación genérica');

          this.doWhenSpecificOperationFinish(res);

          this.finishGenericOperation.emit(true);

        },
        error: (e: any) => {

          this.errorInQuery();

          this.finishGenericOperation.emit(false);

          Swal.fire({
            icon: 'error',
            title: 'Importante',
            text: 'No se ha podido realizar la operación',
          })
          this.logger.error(e);
        },
        complete: () => {
          this.logger.info('complete')
        }
      });

  }

  protected doWhenSpecificOperationFinish(item?: any) {
  }

  protected setModalConfiguration(item: any, modalOptions: NgbModalOptions, title: string = 'Detalle del Registro') {

    const modalRef = this.modalService.open(ModalWrapperComponent, modalOptions);

    modalRef.componentInstance.component = this.componentModal;
    modalRef.componentInstance.componentData = item;
    modalRef.componentInstance.modalData = {
      title: title,
      useHeader: true,
      headerClasses: '',
      closable: true,
    };

    modalRef.dismissed.subscribe((hasBeenEntityModified: any) => {
      if (hasBeenEntityModified) {
        this.doWhenEntityHasBeenEntityModified();
      }
    })
  }

  protected doWhenEntityHasBeenEntityModified() {
  }

  protected showModalQuickAdd(item: any, size: string = 'md') {

    this.beforeStartQuery();

    this.setModalConfigurationForAdd(item, size)

    this.storeService.setIsLoading(false);
  }

  private setModalConfigurationForAdd(item: any, size: string = 'md') {

    const modalOptions: NgbModalOptions = {
      size: size,
      modalDialogClass: '',
      windowClass: '',
    };

    const modalRef = this.modalService.open(ModalWrapperComponent, modalOptions);

    modalRef.componentInstance.component = this.componentModal;
    modalRef.componentInstance.componentData = item;
    modalRef.componentInstance.modalData = {
      title: 'Detalle del Registro',
      useHeader: false,
      headerClasses: '',
      closable: true,
    };
  }

  //#endregion
  // -------------------------------------------------------------------------------


  // -------------------------------------------------------------------------------
  // #region BulkOperations
  // -------------------------------------------------------------------------------

  protected deleteBulk(obs$: any) {

    let arrObs$: any[] = [];

    if (Array.isArray(obs$)) {
      arrObs$ = obs$;
    } else {
      arrObs$.push(obs$);
    }

    this.beforeStartQuery();

    forkJoin([arrObs$])
      .pipe(
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe({
        next: () => {

          this.storeService.setIsLoading(false);
          this.logger.info('Eliminando con deleteBulk');

          this.finishDeleteBulkOperation.emit(true);


        },
        error: (e: any) => {
          this.errorInQuery();

          this.finishDeleteBulkOperation.emit(false);

          Swal.fire({
            icon: 'error',
            title: 'Importante',
            text: 'No se ha podido realizar el borrado masivo',
          })
          this.logger.error(e);
        },
        complete: () => {
          this.logger.info('complete doDeleteBulkOperation')
        }
      });

  }


  protected getIdsToDoBulkOperation() {
    return this.checkboxesIdsChecked;
  }


  protected doChangeRowCheckboxStatus(event: any) {
    const idClicked = +event.target.value;

    if (event.target.checked) {
      this.addIdToArrayWithNoDuplicates(idClicked);
    } else {
      const index = this.checkboxesIdsChecked.indexOf(idClicked, 0);
      if (index > -1) {
        this.checkboxesIdsChecked.splice(index, 1);
      }
    }

    this.setNumElementsChecked(this.checkboxesIdsChecked.length);
  }


  protected doChangeAllCheckboxesStatus(event: any) {
    console.log(event)
    if (event.target.checked) {
      this.checkboxes.forEach((element) => {

        element.nativeElement.checked = true;

        const id = +element.nativeElement.id.substring(3);

        this.addIdToArrayWithNoDuplicates(id);
        this.logger.info('🚀 ~ this.checkboxesIdsChecked:', this.checkboxesIdsChecked);
      });
    } else {
      this.checkboxes.forEach((element) => {
        element.nativeElement.checked = false;
      });
      this.checkboxesIdsChecked = [];
      this.logger.info('🚀 ~ this.checkboxesIdsChecked:', this.checkboxesIdsChecked);
    }

    this.setNumElementsChecked(this.checkboxesIdsChecked.length);
  }


  private setNumElementsChecked(num: number) {
    // this.storeService.setNumElementsChecked(num);
    this.numElementsChecked = num;
  }


  private addIdToArrayWithNoDuplicates(idClicked: number) {
    this.checkboxesIdsChecked.push(idClicked);
    this.checkboxesIdsChecked = [...new Set<number>(this.checkboxesIdsChecked)];
  }

  //#endregion
  // -------------------------------------------------------------------------------


  // -------------------------------------------------------------------------------
  // #region CSS & interface messages
  // -------------------------------------------------------------------------------

  /**
   * Get css class for a column
   * @param alias Column name alias
   * @returns Css class
   */
  public getCssClassColumn(alias: string) {
    return this.dataListWrapper.getCssClassColumn(alias);
  }

  /**
   * Get human readable value for true and false
   * @param rawValue boolean value to be converted to a human readable value
   * @returns
   */
  public getBooleanValueToShow(rawValue: boolean) {
    return rawValue ? 'Sí' : 'No';
  }

  /**
   * Get most used css class for boolean values
   * @param rawValue
   * @returns
   */
  public getCssClassForBooleanValue(rawValue: boolean) {
    return rawValue ? StatusCssClass.Sucess : StatusCssClass.Danger;
  }
  //#endregion
  // -------------------------------------------------------------------------------


  // -------------------------------------------------------------------------------
  // #region Messages
  // -------------------------------------------------------------------------------


  protected showMessages(content: any, type: any = 'success', dismissibleMiliseconds: number = 3000) {
    this.alertComponent.content = content;
    this.alertComponent.type = type;
    this.alertComponent.dismissibleMiliseconds = dismissibleMiliseconds ?? this.alertComponent.dismissibleMiliseconds ?? environment.dismissibleMiliseconds;
    this.alertComponent.dismissAlert();
  }

  //#endregion
  // -------------------------------------------------------------------------------

}

// Angular modules
import { Component, EventEmitter, Output } from '@angular/core';
import { OnInit } from '@angular/core';
import { Router } from '@angular/router';

// Internal modules
import { environment } from '@env/environment';
import { EventBusData } from '@models/event-bus-data.model';
import { EventBusService } from '@services/event-bus.service';

@Component({
  selector: 'lockbin-layout-header',
  templateUrl: './layout-header.component.html',
  styleUrls: ['./layout-header.component.scss']
})
export class LayoutHeaderComponent implements OnInit {

  @Output() newItemEvent = new EventEmitter<string>();

  public appName: string = environment.appName;
  public isMenuCollapsed = true;

  constructor
    (
      private router: Router,
      private eventBusService: EventBusService,
    ) {
  }

  public ngOnInit(): void {

  }

  /**
   * Envía el evento de click en el menú de cambio de sidebar
   * @param event Event
   */
  onClickActiveSidebar(event: Event) {
    event.preventDefault();
    // Emitimos evento de haber hecho click en la apertura de sidebar
    this.eventBusService.emit(new EventBusData('lockbin-sidebar:active-sidebar', { originSrc: 'LayoutHeaderComponent' }));
  }

  // -------------------------------------------------------------------------------
  // NOTE Init ---------------------------------------------------------------------
  // -------------------------------------------------------------------------------

  // -------------------------------------------------------------------------------
  // NOTE Actions ------------------------------------------------------------------
  // -------------------------------------------------------------------------------

  public async onClickLogout(): Promise<void> {
    // NOTE Redirect to login
    this.router.navigate(['/auth/login']);
  }

  // -------------------------------------------------------------------------------
  // NOTE Computed props -----------------------------------------------------------
  // -------------------------------------------------------------------------------

  // -------------------------------------------------------------------------------
  // NOTE Helpers ------------------------------------------------------------------
  // -------------------------------------------------------------------------------

  // -------------------------------------------------------------------------------
  // NOTE Requests -----------------------------------------------------------------
  // -------------------------------------------------------------------------------

  // -------------------------------------------------------------------------------
  // NOTE Subscriptions ------------------------------------------------------------
  // -------------------------------------------------------------------------------

}

<div class="drodown" ngbDropdown #operationsDrop="ngbDropdown" *ngIf="rowOperations && rowOperations.length > 0">
  <button class="dropdown-toggle btn btn-icon btn-trigger" data-bs-toggle="dropdown" ngbDropdownToggle><em class="icon ni ni-more-h"></em></button>
  <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
      <ul class="link-list-opt no-bdr">
        <li *ngFor="let operation of rowOperations" [ngClass]="(operation.alias === 'divider') ? 'divider' : ''">
          <span class="link-list-opt-item" *ngIf="operation.alias !== 'divider'" (click)="onClick(operation.alias); operationsDrop.close();"><em class="icon ni" [ngClass]="'ni-' + operation.icon"></em><span>{{ operation.title }}</span></span>
        </li>
      </ul>
  </div>
</div>

import { Pipe, PipeTransform } from '@angular/core';
import { PREDEFINED_ROLES } from '@security/enums/role.enum';
import { UserViewDto } from '@security/models';

@Pipe({
  name: 'userViewDtoPipe'
})
export class UserViewDtoPipe implements PipeTransform {



  transform(entity: UserViewDto, ...args: unknown[]): string {

    if (entity) {

      switch (args[0]) {
        case 'short':
          return this.getUserDescription(entity, args[0]) || '';
        case 'medium':
          return this.getUserDescription(entity, args[0]) || '';
        case 'long':
          return this.getUserDescription(entity, args[0]) || '';

        default:
          return this.getUserDescription(entity, '') || '';
      }
    }

    return '-';
  }


  private getUserDescription(entity: UserViewDto, modifier: string): string {

    let toret = '';

    const roleName: string = entity.roles[0] || '';

    switch (roleName) {

      case PREDEFINED_ROLES.ROLE_ROOT:
        toret = entity.username + '(' + roleName + ')';
        break;

      case PREDEFINED_ROLES.ROLE_MAINTENANCE:
        toret = entity.username || '' + this.getCustomerName(entity);
        break;

      case PREDEFINED_ROLES.ROLE_MANAGER:
        toret = entity.username + '(' + roleName + ')';
        break;

      case PREDEFINED_ROLES.ROLE_PUBLIC:
        toret = entity.username + '(' + roleName + ')';
        break;

      case PREDEFINED_ROLES.ROLE_SUBCONTRACT:
        toret = entity.username || '' + this.getCustomerName(entity);
        break;

      case PREDEFINED_ROLES.ROLE_CUSTOMER:
        toret = entity.username || '' + this.getCustomerName(entity);
        break;

      default:
        toret = entity.username + '(' + roleName + ')';
        break;
    }

    return toret;


  }

  private getCustomerName(entity: UserViewDto) {

    if(entity.customer.id === 0) return '';

    return '( ' + entity.name + ')';
  }

}

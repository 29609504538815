// Models
import { Region } from "@configuration/regions/models";
import { QueryFilter } from "@models/api-request.model";
import { Exportable, Exporter } from "@models/index";

export class City implements Exportable {


  id:        number = 0;
  version:   number | undefined;
  name:      string | undefined;

  regionId:  number = 0;

  region:    Region = new Region();


  constructor(){}

}

export class CityFilter implements QueryFilter {

  name:      string | undefined;

}


export class CityExporter implements Exporter  {

  name;
  region;

  constructor() {

    this.name= {
      nameToShow: 'Nombre'
    };
    this.region = {
      name: {
        nameToShow: 'Región'
      }
    };
  }
}





import { Injectable } from '@angular/core';
import { Subject, Subscription, filter, map } from 'rxjs';
import { EventBusData } from '@models/event-bus-data.model';

@Injectable({
  providedIn: 'root'
})
export class EventBusService {

  private subject$ = new Subject<EventBusData>();

  constructor() { }

  emit(event: EventBusData) {
    this.subject$.next(event);
  }

  on(eventName: string, action: any): Subscription {
    return this.subject$.pipe(
      filter((e: EventBusData) => e.name === eventName),
      map((e: EventBusData) => e.value)).subscribe(action);
  }
}

// Angular modules
import { Injectable, QueryList } from '@angular/core';

// External modules
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';

// Internal modules
import { environment } from '@env/environment';
import { StorableQueryParameter } from '@models/api-request.model';
import { UserViewDto } from '@security/models';

/**
 * Angular Application State Management: You Do (Not) Need External Data Stores like NgRx, Redux, and Other
 * https://www.maestralsolutions.com/angular-application-state-management-you-do-not-need-external-data-stores/
 */
@Injectable()
export class StoreService {
  // NOTE Data
  private readonly _pageTitleSource = new BehaviorSubject<string>(environment.appName);
  private readonly _isLoadingSource = new BehaviorSubject<boolean>(false);
  private readonly _isError = new BehaviorSubject<boolean>(false);
  private readonly _numDataToExport = new BehaviorSubject<number>(0);
  private readonly _isSavingUserPersistentData = new BehaviorSubject<boolean>(false);


  private readonly _role = new BehaviorSubject<string>('');
  private readonly _user = new BehaviorSubject<UserViewDto>(new UserViewDto());



  // NOTE Events
  readonly pageTitle$ = this._pageTitleSource.asObservable();
  readonly isLoading$ = this._isLoadingSource.asObservable();
  readonly isError$ = this._isError.asObservable();
  readonly numDataToExport$ = this._numDataToExport.asObservable();
  readonly isSavingUserPersistentData$ = this._isSavingUserPersistentData.asObservable();
  readonly role$ = this._role.asObservable();
  readonly user$ = this._user.asObservable();


  constructor(private translateService: TranslateService) { }

  // -------------------------------------------------------------------------------
  // NOTE Page title ---------------------------------------------------------------
  // -------------------------------------------------------------------------------

  public getPageTitle(): string {
    return this._pageTitleSource.getValue();
  }

  public setPageTitle(title: string, translate: boolean = true): void {
    const pageTitle = translate ? this.translateService.instant(title) : title;
    this._pageTitleSource.next(pageTitle);
  }

  // -------------------------------------------------------------------------------
  // NOTE Is loading ---------------------------------------------------------------
  // -------------------------------------------------------------------------------

  public getIsLoading(): boolean {
    return this._isLoadingSource.getValue();
  }

  public setIsLoading(state: boolean): void {
    this._isLoadingSource.next(state);
  }

  // -------------------------------------------------------------------------------
  // NOTE Is error   ---------------------------------------------------------------
  // -------------------------------------------------------------------------------

  public getIsError(): boolean {
    return this._isError.getValue();
  }

  public setIsError(state: boolean): void {
    this._isError.next(state);
  }

  // -------------------------------------------------------------------------------
  // NOTE numDataToExport   --------------------------------------------------------
  // -------------------------------------------------------------------------------

  public getNumDataToExport(): number {
    return this._numDataToExport.getValue();
  }

  public setNumDataToExport(state: number): void {
    this._numDataToExport.next(state);
  }

  // -------------------------------------------------------------------------------
  // NOTE role   -------------------------------------------------------------------
  // -------------------------------------------------------------------------------

  public getRole(): string {
    return this._role.getValue();
  }

  public setRole(state: string): void {
    this._role.next(state);

  }

  // -------------------------------------------------------------------------------
  // NOTE _isSavingUserPersistentData   --------------------------------------------
  // -------------------------------------------------------------------------------

  public getIsSavingUserPersistentData(): boolean {
    return this._isSavingUserPersistentData.getValue();
  }

  public setIsSavingOrRetrievingUserPersistentData(state: boolean): void {
    this._isSavingUserPersistentData.next(state);
  }

  // -------------------------------------------------------------------------------
  // NOTE user   -------------------------------------------------------------------
  // -------------------------------------------------------------------------------

  public getUser(): UserViewDto {
    return this._user.getValue();
  }

  public setUser(state: UserViewDto): void {
    this._user.next(state);
  }
}
